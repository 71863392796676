/*
 * @Author: 齐白宇 
 * @Date: 2019-04-01 11:38:45 
 * @Last Modified by: 褚会茹
 * @Last Modified time: 2021-09-07 09:06:27
 */
import Vue from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import 'es6-promise/auto'
import Vuex from 'vuex'
import 'ant-design-vue/dist/antd.css'
import VueRouter from 'vue-router'
import router from './router/index'
import '@/assets/css/style.css'
import '@/assets/iconfont/iconfont.css'
// import '@/assets/iconfont-new/iconfont-new.css'
import commen from '@/utils/commen.js'
import msg from '@/utils/msg.js'
import city from '@/utils/city.js'
import api from './api/index'
import VueCookies from 'vue-cookies'
import { handleUrl } from '@/utils/http'
import rightHeader from '@/components/basic/rightHeader.vue'
import btn from '@/components/basic/Button.vue'
import store from './store'
import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn')

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.prototype.$handleUrl = handleUrl;
Vue.prototype.$leftMenus = router.options.routes[1].children;
Vue.prototype.$commen = commen;
Vue.prototype.$api = api;
Vue.prototype.$msg = msg;
Vue.prototype.$city = city;


Vue.component('pxkeji-rightHeader', rightHeader);
Vue.component('pxkeji-btn', btn);
Vue.component('p-button', btn);


Vue.use(VideoPlayer)
Vue.use(Vuex);
Vue.use(VueRouter)
Vue.use(Antd)
Vue.use(VueCookies)
new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')