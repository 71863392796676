/*
 * @Author: 齐白宇 
 * @Date: 2019-09-19 11:15:41 
 * @Last Modified by: 褚会茹
 * @Last Modified time: 2019-10-29 10:42:09
 */


import { fetch, postJson } from '@/utils/http.js'



/**
 * 分页列表
 *
 * @param {*} pageNow 当先页数;默认为1
 * @param {*} pageSize 每页条数;默认为10
 * @param {*} dictionaryId 字典的ID
 * @returns
 */
function pageList(pageNow, pageSize, dictionaryId) {
    return new Promise((resolve, reject) => {
        fetch('/dictionaryItem/getPageList', {
            params: {
                pageNow,
                pageSize,
                dictionaryId,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 新增
 *
 * @param {*} dictionaryId 字典ID
 * @param {*} itemName 字典子项名称
 * @param {*} orderno 排序
 * @param {*} itemValue 值
 * @param {*} parentId 父级id
 * @param {*} isShow 是否在前台显示
 * @returns
 */
function save(dictionaryId, itemName, orderno, itemValue, parentId, isShow, picture) {
    return new Promise((resolve, reject) => {
        postJson('/dictionaryItem/save', { itemName }, {
            dictionaryId,
            itemName,
            orderno,
            itemValue,
            parentId,
            isShow,
            picture
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 更新 
 *
 * @param {*} id 编号
 * @param {*} dictionaryId 字典ID
 * @param {*} itemName 字典子项名称
 * @param {*} orderno 排序
 * @param {*} itemValue 值
 * @param {*} parentId 父级id
 * @param {*} isShow 是否在前台显示
 * @returns
 */
function update(id, dictionaryId, itemName, orderno, itemValue, parentId, isShow, picture) {
    return new Promise((resolve, reject) => {
        postJson('/dictionaryItem/update', { id, itemName }, {
            id,
            dictionaryId,
            itemName,
            orderno,
            itemValue,
            parentId,
            isShow,
            picture
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 查询详情
 *
 * @param {*} id 编号
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/dictionaryItem/getById', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 删除
 *
 * @param {*} id 编号
 * @returns
 */
function del(id) {
    return new Promise((resolve, reject) => {
        fetch('/dictionaryItem/delete', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 获取地区层级字典子项
 *
 * @param {*} dictionaryId 编号
 * @returns
 */
function tree(dictionaryId) {
    return new Promise((resolve, reject) => {
        fetch('/dictionaryItem/getTreeByDictionaryId', {
            params: {
                dictionaryId,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *获取所有字典子项
 *
 * @param {*} dictionaryId
 * @returns
 */
function getDictionaryItem(dictionaryId) {
    return new Promise((resolve, reject) => {
        fetch('/dictionaryItem/getDictionaryItem', {
            params: {
                dictionaryId,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
export default {
    pageList,
    save,
    update,
    getById,
    del,
    tree,
    getDictionaryItem,
}