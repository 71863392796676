/*
 * @Author: 齐白宇 
 * @Date: 2019-09-17 11:27:03 
 * @Last Modified by: 褚会茹
 * @Last Modified time: 2022-01-11 17:58:38
 */

import { fetch, postJson } from '@/utils/http.js'


/**
 * 分页列表
 *
 * @param {*} pageNow 当前页
 * @param {*} pageSize 当前页条数
 * @param {*} adzoneId 广告位id
 * @param {*} adzoneName 广告位名称
 * @param {*} name 名称
 * @param {*} type 资源类型:0—图文,1—视频
 * @param {*} status 状态:0—禁用,1—启用
 * @returns
 */
function getPageList(pageNow, pageSize, advZoneId, name, status, sourceType) {
    return new Promise((resolve, reject) => {
        fetch('/advertisement/getPageList', {
            params: {
                pageNow,
                pageSize,
                advZoneId,
                name,
                status,
                sourceType
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改状态
 *
 * @param {*} status 状态:0—禁用 ,1—启用
 * @param {*} ids 编号，可以用逗号隔开
 * @returns
 */
function updateStatus(status, ids) {
    return new Promise((resolve, reject) => {
        fetch('/advertisement/updateStatus', {
            params: {
                status,
                ids,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 根据id搜索
 *
 * @param {*} id 编号
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/advertisement/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 更新
 *
 * @param {*} id 编号
 * @param {*} name 名称
 * @param {*} advZoneId 广告位id
 * @param {*} resourceslink 资源地址
 * @param {*} status 状态 0--禁用,1--启用
 * @returns
 */
function saveOrUpdate(data) {
    return new Promise((resolve, reject) => {
        postJson('/advertisement/saveOrUpdate', { id: data.id, name: data.name, }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 删除
 *
 * @param {*} id 编号
 * @returns
 */
function del(ids) {
    return new Promise((resolve, reject) => {
        fetch('/advertisement/deleteBatch', {
            params: {
                ids,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 获取资源类型
 */
function getSourceTypes() {
    return new Promise((resolve, reject) => {
        fetch('/advertisement/getSourceTypes', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改排序
 * @param {*} id 
 * @param {*} up 
 * @returns 
 */
function upOrDown(id, up) {
    return new Promise((resolve, reject) => {
        fetch('/advertisement/upOrDown', {
            params: {
                id, up,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


export default {
    getPageList,
    updateStatus,
    getById,
    saveOrUpdate,
    del,
    getSourceTypes,
    upOrDown
}