/*
 * @Author: 齐白宇 
 * @Date: 2019-09-17 10:11:50 
 * @Last Modified by: 齐白宇
 * @Last Modified time: 2019-09-17 20:23:43
 */

import { fetch, postJson } from '@/utils/http.js'

/**
 * 分页列表
 *
 * @param {*} pageNow 当前页
 * @param {*} pageSize 当前页数条数
 * @param {*} name 名字
 * @param {*} status 状态:0—禁用 ,1—启用
 * @returns
 */
function getPageList(pageNow, pageSize, name, status) {
    return new Promise((resolve, reject) => {
        fetch('/advZone/getPageList', {
            params: {
                pageNow,
                pageSize,
                name,
                status,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改状态
 *
 * @param {*} status 状态:0—禁用 ,1—启用
 * @param {*} ids 编号，可以用逗号隔开
 * @returns
 */
function updateStatus(status, ids) {
    return new Promise((resolve, reject) => {
        fetch('/advZone/updateStatus', {
            params: {
                status,
                ids,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 根据id搜索
 *
 * @param {*} id 编号
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/advZone/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 更新
 *
 * @param {*} id 编号
 * @param {*} name 名称
 * @param {*} size 尺寸
 * @param {*} status 状态
 * @returns
 */
function saveOrUpdate(data) {
    return new Promise((resolve, reject) => {
        postJson('/advZone/saveOrUpdate', { id: data.id, name: data.name, }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 删除
 *
 * @param {*} id 编号
 * @returns
 */
function del(id) {
    return new Promise((resolve, reject) => {
        fetch('/advZone/delete', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 * 全部
 *
 * @returns
 */
function getUsableList(name) {
    return new Promise((resolve, reject) => {
        fetch('/advZone/getUsableList', {
            params: {
                name
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}



export default {
    getPageList,
    updateStatus,
    getById,
    saveOrUpdate,
    del,
    getUsableList,
}