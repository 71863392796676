const breadcrumb = {
    namespaced: true,
    state: {
        breadcrumbs: []
    },
    mutations: {
        SET_BREADCRUMB(state, payload) {
            state.breadcrumbs = payload;
        }
    },
    getters: {
        getBreadcrumb: (state) => {
            return state.breadcrumbs;
        }
    },
    actions: {
        setBreadcrumb({ commit }, payload) {
            commit("SET_BREADCRUMB", payload);
        },
    }
}
export default breadcrumb;