/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 齐白宇
 * @Last Modified time: 2019-09-23 16:04:31
 */

import { fetch, postJson } from '@/utils/http.js'



/**
 *分页列表
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @param {*} name
 * @returns
 */
function pageList(pageNow, pageSize, name) {
    return new Promise((resolve, reject) => {
        fetch('/dictionaries/getPageList', {
            params: {
                pageNow,
                pageSize,
                name
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 查询所有
 * @returns 
 */
function getAll() {
    return new Promise((resolve, reject) => {
        fetch('/dictionaries/getAll', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 根据id搜索
 *
 * @param {*} id 编号
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/dictionaries/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改
 *
 * @param {*} id 编号
 * @param {*} name 名字
 * @param {*} description 描述
 * @param {*} type 类型1键值对2树形3图形
 * @returns
 */
function update(id, name, description, type) {
    return new Promise((resolve, reject) => {
        postJson('/dictionaries/update', { id, name, }, { id, name, description, type }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    pageList,
    getById,
    update,
    getAll
}