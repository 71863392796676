<template>
  <div class="loginwrapper">
    <div class="login_inner">
      <div class="login_left"></div>
      <div class="login_right">
        <h1 style="font-size: 30px">
          <img
            style="margin-right: 10px; width: 48px"
            src="@/assets/img/logo.png"
          />红电职业技能培训系统
        </h1>
        <div class="formItem">
          <img src="../assets/img/userIcon.png" />
          <input
            type="text"
            placeholder="请输入账户"
            v-model="name"
            @keyup.enter="login"
          />
        </div>

        <div class="formItem">
          <img src="../assets/img/passIcon.png" />
          <input
            type="password"
            placeholder="请输入密码"
            v-model="pwd"
            @keyup.enter="login"
          />
        </div>
        <div class="formItem" style="width: 240px">
          <img src="../assets/img/yzm.png" />
          <input
            type="text"
            id="code"
            name="code"
            v-model="code"
            placeholder="请输入验证码"
            @keyup.enter="login"
          />
          <img
            id="code"
            style="margin-left: 256px; margin-top: -45px"
            class="code"
            :src="imageUrl"
            alt="点击刷新验证码"
            @click="GetCode"
          />
        </div>
        <a-alert
          :message="errorMsg"
          class="login_tip"
          v-if="!isPass"
          type="error"
          banner
        />
        <a-button
          type="primary"
          class="loginBtn"
          @click="login"
          block
          :loading="isloading"
          >登 录</a-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { handleUrl } from "@/utils/http.js";
let that;
export default {
  name: "login",
  data() {
    return {
      name: "",
      pwd: "",
      code: "",
      isloading: false,
      isPass: true,
      errorMsg: "",
      imageUrl: "",
      guid: "",
    };
  },
  mounted: function () {
    that = this;
    that.guid = that.$commen.guid();
    that.GetCode();
    if (that.$cookies.get(that.$msg.user.Authentication)) {
      that.$router.push({ path: "/" });
    }
  },
  watch: {
    name: () => {
      if (that.name == "") {
        that.isPass = true;
      }
    },
    pwd: () => {
      if (that.pwd == "") {
        that.isPass = true;
      }
    },
  },

  methods: {
    GetCode() {
      that.imageUrl = handleUrl(
        "/code/getCode?uuid=" + that.guid + "&r=" + Math.random()
      );
    },
    login() {
      if (that.name == "") {
        that.isPass = false;
        that.errorMsg = "请输入帐号";
        return;
      }
      if (that.pwd == "") {
        that.isPass = false;
        that.errorMsg = "请输入密码";
        return;
      }
      if (that.code == "") {
        that.isPass = false;
        that.errorMsg = "请输入验证码";
        return;
      }

      that.isloading = true;
      that.$api.user
        .login(that.name, that.pwd, that.code, that.guid)
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            that.$cookies.set(
              that.$msg.user.Authentication,
              res.data.Authentication
            ); //登录凭证
            let rolelist = [];
            for (let j = 0; j < res.data.authority.length; j++) {
              let item = res.data.authority[j];
              if (item.value instanceof Array) {
                for (let i = 0; i < item.value.length; i++) {
                  rolelist.push(item.type + "-" + item.value[i]);
                }
              }
            }
            localStorage.setItem(that.$msg.user.authority, rolelist.join(","));
            that.$cookies.set(that.$msg.user.username, that.name); //登录用户名
            that.$router.push({ path: "/" });
          } else {
            that.isPass = false;
            that.errorMsg = res.errorMsg;
          }
          that.isloading = false;
        })
        .catch(() => {
          that.isloading = false;
        });
    },
  },
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
}
.loginwrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(../assets/img/login_bg.png) no-repeat center center;
  background-size: 100% 100%;
}

.login_inner {
  width: 1265px;
  height: 594px;
  background-color: #ffffff;
  box-shadow: 0px 6px 16px 0px rgba(101, 160, 255, 0.36);
  position: absolute;
  top: 50%;
  margin-top: -297px;
  left: 50%;
  margin-left: -632.5px;
}
.login_left {
  width: 716px;
  height: 594px;
  background: url(../assets/img/form_bg.jpg) no-repeat center center;
  background-size: 100% 100%;
  position: relative;
  float: left;
}
.login_left img {
  position: absolute;
  bottom: 25px;
  left: 28px;
}
.login_right {
  float: right;
  width: 549px;
}
.login_right > h1 {
  font-size: 30px;
  color: #2553a8;
  margin: 102px 0 82px;
  text-align: center;
}
.formItem {
  width: 342px;
  height: 44px;
  border: solid 1px #d6d6d6;
  margin-left: 103px;
  margin-bottom: 12px;
}
.formItem > img,
.formcode > img {
  float: left;
  margin: 12px 0 13px 13px;
}
.formItem > input {
  width: 200px;
  padding-left: 5px;
  height: 42px;
  line-height: 42px;
  outline: none;
  border: none;
  float: left;
}
.formItem2 {
  width: 342px;
  height: 44px;
  margin-left: 103px;
  margin-bottom: 12px;
}
.formcode {
  width: 188px;
  height: 44px;
  border: solid 1px #d6d6d6;
  float: left;
}
.formcode > input {
  width: 150px;
  padding-left: 5px;
  height: 44px;
  line-height: 44px;
  outline: none;
  border: none;
  float: left;
}
.codeImg {
  width: 97px;
  height: 47px;
  float: left;
  margin: 0 6px;
}
.codeImg > img {
  width: 100%;
  height: 100%;
}
.changeBtn {
  font-size: 14px;
  color: #646464;
  line-height: 47px;
}
.forgetPass {
  margin-left: 103px;
  text-align: left;
}
.forgetPass a {
  font-size: 16px;
  color: #6d6d6d;
}
.loginBtn {
  width: 345px;
  height: 48px;
  background: #5e9dff;
  border: none;
  color: #fff;
  outline: none;
  cursor: pointer;
  margin-top: 34px;
  margin-left: 103px;
}

.login_tip {
  width: 345px;
  margin: 0 auto;
  margin-top: 30px;
}
.code {
  height: 44px;
  width: 85px;
  cursor: pointer;
  float: left;
}
</style>

