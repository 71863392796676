/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 褚会茹
 * @Last Modified time: 2019-12-31 14:32:27
 */

import { fetch, postJson } from '@/utils/http.js'


/**
 * 所有考试
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @param {*} examId
 * @returns
 */
function getPageListForExam(pageNow, pageSize, testName, examId) {
    return new Promise((resolve, reject) => {
        fetch('/testPaper/getPageListForExam', {
            params: {
                pageNow,
                pageSize,
                testName,
                examId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 * 保存
 *
 * @param {*} testpaperName
 * @param {*} examId
 * @param {*} subjectIds
 * @returns
 */
function saveOrUpdate(data) {
    return new Promise((resolve, reject) => {
        postJson('/testPaper/saveOrUpdate', { id: data.id, testpaperName: data.testpaperName, }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 删除
 *
 * @param {*} id
 * @returns
 */
function removeById(id) {
    return new Promise((resolve, reject) => {
        fetch('/testPaper/deleteById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 * 详情
 *
 * @param {*} id
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/testPaper/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


export default {
    getPageListForExam,
    saveOrUpdate,
    removeById,
    getById,
}