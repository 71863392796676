/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 贾晓红
 * @Last Modified time: 2019-12-27 09:44:29
 */

import { fetch, post, postJson } from '@/utils/http.js'



/**
 *获取全部
 *
 * @returns
 */
function getAll() {
    return new Promise((resolve, reject) => {
        fetch('/userGroup/getAll', {
            params: {

            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
//添加
function saveOrUpdate(data) {
    return new Promise((resolve, reject) => {
        postJson('/userGroup/saveOrUpdate', { id: data.id, name: data.name }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *删除
 *
 * @param {*} id
 * @returns
 */
function removeById(id) {
    return new Promise((resolve, reject) => {
        fetch('/userGroup/delete', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function getGroupMemberPageList(pageNow, pageSize, groupId, name, sourceType) {
    return new Promise((resolve, reject) => {
        fetch('/userGroup/getGroupMemberPageList', {
            params: {
                pageNow, pageSize, groupId, name, sourceType
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function removeUsers(userIds, groupId) {
    return new Promise((resolve, reject) => {
        post('/userGroup/removeUsers', {
            userIds, groupId
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function addUsers(userIds, groupId) {
    return new Promise((resolve, reject) => {
        post('/userGroup/addUsers', {
            userIds, groupId
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


export default {
    getAll,
    saveOrUpdate,
    removeById,
    removeUsers,
    addUsers,
    getGroupMemberPageList
}