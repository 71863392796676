import { render, staticRenderFns } from "./leftMenu.vue?vue&type=template&id=dc52e05c"
import script from "./leftMenu.vue?vue&type=script&lang=js"
export * from "./leftMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports