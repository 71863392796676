import { fetch, post, postJson, download } from '@/utils/http.js'
/**
 * 查询学员分页列表
 * @param {*} pageNow 
 * @param {*} pageSize 
 * @param {*} params 
 * @returns 
 */
function getPagedList(pageNow, pageSize, params) {
    return new Promise((resolve, reject) => {
        post('/student/getPagedList', { pageNow, pageSize, ...params }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })

}
/**
 * 添加或修改学员
 * @param {*} data 
 * @returns 
 */
function saveOrUpdateStudent(data) {
    return new Promise((resolve, reject) => {
        postJson('/student/saveOrUpdateStudent', { id: data.id, fullName: data.fullName }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 删除学员
 * @param {*} id 
 * @returns 
 */
function deleteById(id) {
    return new Promise((resolve, reject) => {
        fetch('/student/remove', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}
/**
 * 查询学员详情
 * @param {*} id 
 * @returns 
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/student/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改学员状态
 * @param {*} params 
 * @returns 
 */
function changeStatus(params) {
    return new Promise((resolve, reject) => {
        post('/student/changeStatus', params).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })

}
/**
 * 保存学员基本信息
 * @param {*} params 
 * @returns 
 */
function saveOrUpdateBaseInfo(params) {
    return new Promise((resolve, reject) => {
        postJson('/student/saveOrUpdateBaseInfo', {}, params).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })

}
/**
 * 查询学员详情
 * @param {*} studentId 
 * @returns 
 */
function getStudentDetailById(studentId) {
    return new Promise((resolve, reject) => {
        fetch('/student/getStudentDetailById', {
            params: {
                studentId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function importData() {
    return download("/student/importData", {
    });
}
function exportResource(id) {
    return download("/resource/downloadResourceById", {
        id
    });
}
function exportData(params) {
    return download("/student/exportData", { ...params });
}

function downloadTemplate() {
    return download("/student/downloadTemplate", {
    });
}

function getStudentList(params) {
    return new Promise((resolve, reject) => {
        post('/student/getStudentList', params).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })

}
/**
 * 查询所有启用学员列表，用于关联学员的列表查询
 * @param {*} pageNow 
 * @param {*} pageSize 
 * @param {*} params 
 * @returns 
 */
function getStudentPageListNoAuth(pageNow, pageSize, params) {
    return new Promise((resolve, reject) => {
        postJson('/student/getStudentPageListNoAuth', { pageNow, pageSize }, params).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })

}

export default {
    getPagedList,
    saveOrUpdateStudent,
    deleteById,
    getById,
    changeStatus,
    saveOrUpdateBaseInfo,
    getStudentDetailById,
    importData,
    exportData,
    downloadTemplate,
    getStudentList,
    exportResource,
    getStudentPageListNoAuth,
}