/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 贾晓红
 * @Last Modified time: 2019-10-22 11:40:58
 */

import { fetch, postJson } from '@/utils/http.js'

/**
 *根据状态和栏目查询课程分类树形图
 *
 * @param {*} status
 * @param {*} columnId
 * @returns
 */
function getTypeTree(status, columnId) {
    return new Promise((resolve, reject) => {
        fetch('/resourceType/getTypeTree', {
            params: {
                status,
                columnId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function getUseTree(status, columnId) {
    return new Promise((resolve, reject) => {
        fetch('/resourceType/getUseTree', {
            params: {
                status,
                columnId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *添加
 *
 * @param {*} name  名称
 * @param {*} remark  描述
 * @param {*} columnId 栏目id
 * @param {*} parentId 父类id
 * @param {*} orderno 排序
 * @returns
 */
function saveOrUpdate(data) {
    return new Promise((resolve, reject) => {
        postJson('/resourceType/saveOrUpdate', { id: data.id, name: data.name }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *删除
 *
 * @param {*} id
 * @returns
 */
function removeById(id) {
    return new Promise((resolve, reject) => {
        fetch('/resourceType/delete', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *获取详情
 *
 * @param {*} id
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/resourceType/getById', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *课程分类回显
 *
 * @param {*} id
 * @returns
 */
function getParentIdList(id) {
    return new Promise((resolve, reject) => {
        fetch('/resourceType/getParentIdList', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    getTypeTree,
    getUseTree,
    saveOrUpdate,
    removeById,
    getById,
    getParentIdList,
}