
import systemlog from './systemlog.js' //系统日志
import articlecategory from './articlecategory.js' //文章分类
import article from './article.js' //文章管理
import user from './user.js' //用户
import adzone from './adzone.js' //广告位
import advertisement from './advertisement.js' //广告服务
import appVersion from './appVersion.js' //app版本管理
import file from './file.js' //文件上传
import role from './role.js' //角色
import dictionaries from './dictionaries.js' //字典项
import dictionaryItem from './dictionaryItem.js' //字典子项
import notice from './notice.js' //通知
import systemSetting from './systemSetting.js' //系统设置
import course from './course.js' //课程列表
import courseType from './courseType.js' //课程分类
import courseHour from './courseHour.js' //课时
import friendlink from './friendlink.js' //友链
import message from './message.js' //消息
import questioncategory from './questioncategory.js' //题库分类
import examination from './examination.js' //考试
import subject from './subject.js' //题目
import testPaper from './testPaper.js' //试卷
import statistics from './statistics.js' // 统计
import usergroup from './usergroup.js' //用户组
import certificate from './certificate.js' //证书
import project from './train/project.js' //培训项目
import trainClass from "./train/trainClass.js" // 培训班级
import trainClassNotice from "./train/trainClassNotice.js" // 培训班级公告
import trainEnroll from "./train/trainEnroll.js" // 报名
import student from "./train/student.js" // 学员
import enterprise from "./train/enterprise.js" // 企业
import teacher from "./train/teacher.js" // 教师
import city from "../utils/city.js" // 省市区
import subjectPractice from "./subjectPractice.js"//答题活动
import resourceType from "./resourceType.js" //资源分类
import resource from "./resource.js"//资源
import resourceChapterData from "./resourcechapterdata" //资料管理
import evaluating from "./evaluating.js"//测评
import recruit from "./recruit.js"//招聘
import order from "./order.js"//订单
import reviewTitle from "./reviewTitle.js"//职称评审
import comment from "./comment.js"//评论审核

export default {
    evaluating,
    teacher,
    enterprise,
    student,
    trainEnroll,
    trainClassNotice,
    trainClass,
    project,
    systemlog,
    usergroup,
    statistics,
    articlecategory,
    article,
    user,
    adzone,
    advertisement,
    appVersion,
    file,
    role,
    dictionaries,
    dictionaryItem,
    notice,
    systemSetting,
    course,
    courseType,
    courseHour,
    friendlink,
    message,
    questioncategory,
    examination,
    subject,
    testPaper,
    certificate,
    city,
    subjectPractice,
    resourceType,
    resource,
    resourceChapterData,
    recruit,
    order,
    reviewTitle,
    comment
}