import { fetch } from '@/utils/http.js'


/**
 * 列表
 *
 * @param {*} userName 用户名
 * @param {*} startTime 操作日期起始时间
 * @param {*} endTime 操作日期结束时间
 * @param {*} pageNow
 * @param {*} pageSize
 * @returns
 */
function getPagedList(pageNow,pageSize,userName,startTime,endTime,) {
    return new Promise((resolve, reject) => {
        fetch('/systemlog/getPagedList', {
            params: {
                userName: userName,
                startTime:startTime,
                endTime:endTime,
                pageNow:pageNow,
                pageSize:pageSize,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    getPagedList
}