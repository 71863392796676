import { fetch } from '@/utils/http.js'
import { post } from '@/utils/http.js'
import { postJson } from '@/utils/http.js'
import { download } from '@/utils/http.js'

function deleteById(id) {
    return new Promise((resolve, reject) => {
        fetch('/trainClass/deleteById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

function getPagedList(pageNow, pageSize, name, status, dataZoneList) {
    return new Promise((resolve, reject) => {
        fetch('/trainClass/getPageList', {
            params: {
                pageNow, pageSize, name, status, dataZoneList
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

function saveOrUpdate(data, agencyId) {
    return new Promise((resolve, reject) => {
        postJson('/trainClass/saveOrUpdate', { agencyId }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/trainClass/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


function changeStatus(ids, status) {
    return new Promise((resolve, reject) => {
        fetch('/trainClass/changeStatus', {
            params: {
                ids, status
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


function getStudents(pageNow, pageSize, trainClassId, fullName, idCard, telephone, enterpriseName) {
    return new Promise((resolve, reject) => {
        fetch('/trainClass/getStudents', {
            params: {
                pageNow, pageSize, trainClassId, fullName, idCard, telephone, enterpriseName
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function addStudentToClass(trainClassId, studentIds) {
    return new Promise((resolve, reject) => {
        fetch('/trainClass/addStudentToClass', {
            params: {
                trainClassId, studentIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function removeStudentFromClass(trainClassId, studentIds) {
    return new Promise((resolve, reject) => {
        fetch('/trainClass/removeStudentFromClass', {
            params: {
                trainClassId, studentIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 班级审核
 * @param {*} trainClassAudit  checkStatus 审核结果 classId 班级id auditRemark 意见
 */
function trainClassAudit(trainClassAudit) {
    return new Promise((resolve, reject) => {
        post("/trainClass/trainClassAudit", trainClassAudit).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

function importStudent() {
    return download("/trainClass/importStudent", {
    });
}

function changeClassRecommend(ids, recommend) {
    return new Promise((resolve, reject) => {
        fetch('/trainClass/changeRecommend', {
            params: {
                ids, recommend
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function examinationStatistics(trainClassId, projectId) {
    return new Promise((resolve, reject) => {
        fetch('/trainClass/examinationStatistics', {
            params: {
                trainClassId, projectId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function courseLearnStatistics(trainClassId, projectId) {
    return new Promise((resolve, reject) => {
        fetch('/trainClass/courseLearnStatistics', {
            params: {
                trainClassId, projectId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function deleteFile(id) {
    return new Promise((resolve, reject) => {
        fetch('/trainClassArchive/deleteFile', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function studentLearnStatistics(params) {
    return new Promise((resolve, reject) => {
        fetch('/trainClass/studentLearnStatistics', {
            params
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function getFileList(params) {
    return new Promise((resolve, reject) => {
        fetch('/trainClassArchive/getFileList', {
            params
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function certificateStatistics(trainClassId, projectId, agencyId) {
    return new Promise((resolve, reject) => {
        fetch('/trainClass/certificateStatistics', {
            params: { trainClassId, projectId, agencyId }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function trainClassArchivePageList(params) {
    return new Promise((resolve, reject) => {
        fetch('/trainClassArchive/getPageList', {
            params
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    getStudents,
    addStudentToClass,
    removeStudentFromClass,
    changeStatus,
    getById,
    saveOrUpdate,
    getPagedList,
    deleteById,
    trainClassAudit,
    importStudent,
    changeClassRecommend,
    examinationStatistics,
    courseLearnStatistics,
    studentLearnStatistics,
    getFileList,
    trainClassArchivePageList,
    deleteFile,
    certificateStatistics

}