/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2022-05-11 12:05:46
 */

import {
    fetch,
    postJson,
    download,
    postJsonToBlob
} from '@/utils/http.js'


/**
 *
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @param {*} type
 * @param {*} courseName
 * @param {*} courseFrom
 * @param {*} classType
 * @param {*} status
 * @returns
 */
function getPageList(pageNow, pageSize, classType, typeId, name, status, recommend, isGratis, order, tag) {
    return new Promise((resolve, reject) => {
        fetch('/course/getPageList', {
            params: {
                pageNow,
                pageSize,
                classType,
                typeId,
                name,
                status,
                recommend,
                isGratis,
                order,
                tag
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *获取详情
 *
 * @param {*} id
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/course/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *删除
 *
 * @param {*} id
 * @returns
 */
function removeById(ids) {
    return new Promise((resolve, reject) => {
        fetch('/course/deleteCourse', {
            params: {
                ids,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *修改状态
 *
 * @param {*} id
 * @param {*} status
 * @returns
 */
function updateStatusByIds(ids, status) {
    return new Promise((resolve, reject) => {
        fetch('/course/updateStatus', {
            params: {
                ids,
                status,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


function updateRecommendById(courseId, recommend) {
    return new Promise((resolve, reject) => {
        fetch('/course/updateRecommend', {
            params: {
                courseId,
                recommend,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function updateOpenById(courseId, open) {
    return new Promise((resolve, reject) => {
        fetch('/course/updateOpen', {
            params: {
                courseId,
                open,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function updateRequiredById(courseId, required) {
    return new Promise((resolve, reject) => {
        fetch('/course/updateCourseRequired', {
            params: {
                courseId,
                required,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}



function saveOrUpdate(course) {
    return new Promise((resolve, reject) => {
        postJson('/course/saveOrUpdate', {
            id: course.id,
            name: course.name
        }, course).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function sortCourse(id, isUp) {
    return new Promise((resolve, reject) => {
        fetch('/course/sortCourse', {
            params: {
                id,
                isUp
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 新增章节
 *
 * @param {*} id
 * @param {*} title
 * @param {*} tag
 * @param {*} categoryId
 * @param {*} content
 * @param {*} author
 * @returns
 */
function saveOrUpdateChapter(courseChapter) {
    return new Promise((resolve, reject) => {
        postJson('/courseChapter/saveOrUpdateChapter', {
            title: courseChapter.title,
            id: courseChapter.id
        }, courseChapter).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 删除章节
 *
 * @param {*} chapterId
 * @returns
 */
function deleteChapter(chapterId) {
    return new Promise((resolve, reject) => {
        fetch('/courseChapter/deleteChapter', {
            params: {
                chapterId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 章节详情
 *
 * @param {*} chapterId
 * @returns
 */
function getByChapterId(chapterId) {
    return new Promise((resolve, reject) => {
        fetch('/courseChapter/getByChapterId', {
            params: {
                chapterId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 章节课时列表
 *
 * @param {*} courseId
 * @returns
 */
function getCourseDetail(id) {
    return new Promise((resolve, reject) => {
        fetch('/course/getCourseDetail', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getCourseGroupList() {
    return new Promise((resolve, reject) => {
        fetch('/course/getCourseGroupList', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function sortChapter(id, type) {
    return new Promise((resolve, reject) => {
        fetch('/courseChapter/sortChapter', {
            params: {
                id,
                type
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function importData() {
    return download("/course/importData", {});
}

function downloadTemplate() {
    return download("/course/downloadTemplate", {});
}

function updateCourseSubject(courseHourSubjects) {
    return new Promise((resolve, reject) => {
        postJson('/course/updateCourseSubject', {
            courseId: courseHourSubjects[0].courseId
        }, courseHourSubjects).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getCourseSubject(courseId) {
    return new Promise((resolve, reject) => {
        fetch('/course/getCourseSubject', {
            params: {
                courseId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getCoursesByIds(courseIds) {
    return new Promise((resolve, reject) => {
        fetch('/course/getCoursesByIds', {
            params: {
                courseIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getCourseStatistic(pageNow, pageSize, name) {
    return new Promise((resolve, reject) => {
        fetch('/course/getCourseStatistic', {
            params: {
                pageNow,
                pageSize,
                name
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getCourseStudentDetails(pageNow, pageSize, name, telephone) {
    return new Promise((resolve, reject) => {
        fetch('/course/getCourseStudentDetails', {
            params: {
                pageNow,
                pageSize,
                name,
                telephone
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getStudentCourseDetail(pageNow, pageSize, courseId, studentId) {
    return new Promise((resolve, reject) => {
        fetch('/course/getStudentCourseDetail', {
            params: {
                pageNow,
                pageSize,
                courseId,
                studentId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *获取直播方式
 *
 * @returns
 */
function getLiveType() {
    return new Promise((resolve, reject) => {
        fetch('/course/getLiveType', {
            params: {

            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 *获取直播详情
 *
 * @returns
 */
function getLiveInfo(id) {
    return new Promise((resolve, reject) => {
        fetch('/course/getLiveInfo', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 *结束直播
 *
 * @returns
 */
function endLive(courseId) {
    return new Promise((resolve, reject) => {
        fetch('/course/endLive', {
            params: {
                courseId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 *开启直播
 *
 * @returns
 */
function startLive(courseId) {
    return new Promise((resolve, reject) => {
        fetch('/course/startLive', {
            params: {
                courseId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 *直播课程公告
 *
 * @returns
 */
function getLiveNoticeList(pageNow, pageSize, courseId) {
    return new Promise((resolve, reject) => {
        fetch('/liveNotice/getLiveNoticeList', {
            params: {
                pageNow,
                pageSize,
                courseId,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 *直播公告详情
 *
 * @returns
 */
function getLiveNoticeInfo(id) {
    return new Promise((resolve, reject) => {
        fetch('/liveNotice/getLiveNotice', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 删除通知
 *
 * @param {*} id 编号
 * @returns
 */
function removeLiveNoticeInfo(ids) {
    return new Promise((resolve, reject) => {
        fetch('/liveNotice/removeLiveNoticeByIds', {
            params: {
                ids
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 修改状态
 *
 * @param {*} id 编号
 * @param {*} status 状态值
 * @returns
 */
function updateLiveNoticeStatus(id, status) {
    return new Promise((resolve, reject) => {
        fetch('/liveNotice/updateStatus', {
            params: {
                id,
                status
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 新增
 *
 * @param {*} content 内容
 * @param {*} publishTime 发布时间
 * @returns
 */
function saveOrUpdateLiveNoticeInfo(liveNotice) {
    return new Promise((resolve, reject) => {
        postJson('/liveNotice/saveLiveNotice', {}, liveNotice).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}



/**
 *查看课程的所有课时
 *
 * @returns
 */
function getCourseHourListByCourseId(courseId) {
    return new Promise((resolve, reject) => {
        fetch('/course/getCourseHourListByCourseId', {
            params: {
                courseId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 获取封面图模板
 * @returns 
 */
function getCoursePicTemp() {
    return new Promise((resolve, reject) => {
        fetch('/course/getCoursePicTemp', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 查看预览
 * @param {*} temp 
 * @returns 
 */
function previewCoursePic(temp) {
    return new Promise((resolve, reject) => {
        postJsonToBlob('/course/previewCoursePic', {}, temp).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 异步保存封面图
 * @param {*} temp 
 * @returns 
 */
function saveCoursePicAsync(temp) {
    return new Promise((resolve, reject) => {
        postJson('/course/saveCoursePicAsync', {}, temp).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 生成封面图获取地址
 * @param {*} temp 
 * @returns 
 */
function getCoursePicPath(temp) {
    return new Promise((resolve, reject) => {
        postJson('/course/getCoursePicPath', {}, temp).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function pushMessage(id) {
    return new Promise((resolve, reject) => {
        fetch('/course/pushMessage', {
            params: {id}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
export default {
    pushMessage,
    getPageList,
    getById,
    removeById,
    updateStatusByIds,
    saveOrUpdate,
    updateRecommendById,
    updateOpenById,
    updateRequiredById,
    getCourseDetail,
    getCourseGroupList,
    sortCourse,
    saveOrUpdateChapter,
    deleteChapter,
    getByChapterId,
    sortChapter,
    importData,
    downloadTemplate,
    updateCourseSubject,
    getCourseSubject,
    getCoursesByIds,
    getCourseStatistic,
    getCourseStudentDetails,
    getStudentCourseDetail,
    getLiveType,
    getLiveInfo,
    startLive,
    endLive,
    getCourseHourListByCourseId,
    getLiveNoticeList,
    getLiveNoticeInfo,
    removeLiveNoticeInfo,
    saveOrUpdateLiveNoticeInfo,
    updateLiveNoticeStatus,
    getCoursePicTemp,
    previewCoursePic,
    saveCoursePicAsync,
    getCoursePicPath
}