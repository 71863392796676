import { fetch } from '@/utils/http.js'

function getDays(dataType,forType,startTime,endTime){
    return new Promise((resolve, reject) => {
        fetch('/meter/getDays', {
            params: {
                dataType,forType,startTime,endTime
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getAll(){
    return new Promise((resolve, reject) => {
        fetch('/meter/getAll', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getStatistics(){
    return new Promise((resolve, reject) => {
        fetch('/meter/getStatistics', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default{
    getAll,
    getDays,
    getStatistics
}

