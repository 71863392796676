/*
 * @Author: 齐白宇 
 * @Date: 2019-04-01 08:51:23 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2020-04-02 13:37:16
 */

/**
 * 获取面包屑导航
 *
 * @param {*} datas router路径集合
 * @param {*} route 当前路径
 * @returns
 */
function getThisBreadcrumbs(datas, route) {
    var Breadcrumbs = [{
        meta: {
            name: "首页"
        },
        name: "index",
        path: "/",
    }];
    var thisMatched = route.matched;
    var xunhuaInfo = datas;
    thisMatched.forEach((value) => {
        if (value.path != "" && xunhuaInfo.length > 0) {
            let thisInfo = xunhuaInfo.find(function (routeValue) {
                return routeValue.path == value.path;
            })
            if (thisInfo && thisInfo.children) {
                xunhuaInfo = thisInfo.children;
            } else {
                xunhuaInfo = [];
            }
            Breadcrumbs.push(thisInfo);
        }
    })
    return Breadcrumbs;
}


/**
 *  将数字型0 1 转化为boolean类型
 *  
 * @param {*} s 需要转化的数
 * @returns
 */
function changeIntBoolean(s) {
    return s == 1 ? true : false;
}

/**
 * 将boolean类型转化为数字型0 1 
 *
 * @param {*} s
 * @returns
 */
function changeBooleanInt(s) {
    return s ? 1 : 0;
}

//手机号验证
function validatephone(obj) {
    var partten = /^(13[0-9]|14[5-9]|15[012356789]|16[5-7]|17[0-8]|18[0-9]|19[135689])[0-9]{8}$/;
    if (partten.test(obj)) {
        return true;
    } else {
        return false;
    }
}
//是否为正整数
function isPositiveInteger(s) {
    var re = /^[0-9]+$/;
    return re.test(s)
}
//保留两位小数验证
function isTwoDecimal(s) {
    var re = /^\d+(?:\.\d{1,2})?$/;
    return re.test(s)
}

/**
 * 判断是否为json类型
 *
 * @param {*} str 内容
 * @returns
 */
function isJSON(str) {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }

        } catch (e) {
            return false;
        }
    }
}

// 找到树形结构数据中指定数据及其所有父节点数据
function concatTree(arr, key, val, childKey) {
    var temp = [];
    if (!childKey) {
        childKey = 'children';
    }
    try {
        for (var i = 0; i < arr.length; i++) {
            getNodePath(temp, key, val, childKey, arr[i]);
        }
    } catch (e) {
        return temp;
    }
}
function iswps(format) {
    const formats = [
        'xls', 'xlsx', 'doc', 'docx', 'dotx', 'ppt', 'pptx', 'pdf'
    ];
    return formats.some((item) => {
        return item === format
    });
}
function getNodePath(temp, key, val, childKey, node) {
    temp.push(node);
    if (node[key] == val) {
        throw ("GOT IT!");
    }
    if (node[childKey] && node[childKey].length > 0) {
        for (var i = 0; i < node[childKey].length; i++) {
            getNodePath(node[childKey][i]);
        }
        temp.pop();
    } else {
        temp.pop();
    }
}
function groupArray(array, groupKey) {
    let result = {};
    for (let i = 0; i < array.length; i++) {
        let obj = array[i];
        if (obj[groupKey]) {
            result[obj[groupKey]] = [];
        }
    }
    for (let key in result) {
        for (let j = 0; j < array.length; j++) {
            let obj2 = array[j];
            if (obj2[groupKey] && key == obj2[groupKey]) {
                result[key].push(obj2);
            }
        }
    }
    return result;
}


function toColorRGB(hexStr) {
    // 16进制颜色值的正则
    var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    // 把颜色值变成小写
    var color = hexStr.toLowerCase();
    if (reg.test(color)) {
        // 如果只有三位的值，需变成六位，如：#fff => #ffffff
        if (color.length === 4) {
            var colorNew = "#";
            for (let i = 1; i < 4; i += 1) {
                colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
            }
            color = colorNew;

        }
        // 处理六位的颜色值，转为RGB
        var colorChange = [];
        for (let i = 1; i < 7; i += 2) {
            colorChange.push(parseInt("0x" + color.slice(i, i + 2)));
        }
        return "RGB(" + colorChange.join(",") + ")";
    } else {
        return color;
    }
}

function RGB2Hex(rgb) {
    var re = rgb.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",");
    var hexColor = "#";
    for (let i = 0; i < re.length; i++) {
        let code = parseInt(re[i], 10).toString(16);
        if (code.length == 1) {
            code = "0" + code;
        }
        hexColor += code;
    }
    return hexColor;
}

function guid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";
    return s.join("");
}
function secondToMinute(value) {
    var result = parseInt(value)
    var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    var m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    var s = Math.floor((result % 60)) < 10 ? Math.floor((result % 60)) : Math.floor((result % 60));

    if (s == 0) {
        s = '00'
    } else if (s < 10) {
        s = '0' + s
    }
    var res = '';
    res += h + ':';
    res += m + ':';
    res += s;
    return res;
}

const editorConfig = {
    toolbars: [[
        'source', 'fullscreen', '|', 'undo', 'redo', 'cleardoc', '|',
        'bold', 'italic', 'underline', 'fontborder', 'strikethrough',
        'superscript', 'subscript', 'removeformat', 'formatmatch', 'pasteplain', '|',
        'selectall', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', '|',
        'rowspacingtop', 'rowspacingbottom', 'lineheight', 'autotypeset', '|',
        'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
        'indent', 'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|',
        'touppercase', 'tolowercase', '|', 'link', 'unlink', '|',
        'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
        'anchor', 'simpleupload', 'insertimage', 'insertvideo', 'music', 'attachment',
        'horizontal', 'spechars', 'time', 'date', '|',
        'inserttable', 'insertparagraphbeforetable', 'insertrow',
        'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'edittable', 'edittd',
        'mergedown', 'splittocells', 'splittorows', 'splittocols', 'inserttitle', 'deletecaption', 'deletetable', '|',
        'searchreplace', 'map',
    ]]
}

const applicationId = "f31a1c81-dcd7-4f6b-86d4-f31520bc0ee9";

export default {
    getThisBreadcrumbs,
    changeIntBoolean,
    changeBooleanInt,
    validatephone,
    isPositiveInteger,
    isTwoDecimal,
    isJSON,
    concatTree,
    toColorRGB,
    guid,
    RGB2Hex,
    groupArray,
    iswps,
    secondToMinute,
    editorConfig,
    applicationId
}