/*
 * @Author: 齐白宇 
 * @Date: 2019-09-17 09:56:06 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2020-04-14 11:57:04
 */


import { fetch, post, postJson } from '@/utils/http.js'


/**
 *  用户登录
 *
 * @param {*} name 用户名
 * @param {*} pwd 密码
 * @returns
 */
function login(name, pwd, code, uuid) {
    return new Promise((resolve, reject) => {
        post('/login/userLoginByCode', {
            name: name,
            password: pwd,
            code,
            uuid
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *  分页列表
 *
 * @param {*} pageNow 当先页数
 * @param {*} pageSize 每页条数
 * @param {*} name 用户名或电话
 * @param {*} status 状态
 * @returns
 */
function pageListAdmin(pageNow, pageSize, name, status) {
    return new Promise((resolve, reject) => {
        fetch('/user/getPagedListForAdmin', {
            params: {
                pageNow,
                pageSize,
                name,
                status,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 禁用启用用户
 *
 * @param {*} ids 用户编号，多个可用逗号隔开
 * @param {*} status 状态
 * @returns
 */
function prohibit(ids, status) {
    return new Promise((resolve, reject) => {
        fetch('/user/prohibit', {
            params: {
                ids,
                status,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 根据ID获取详细信息
 *
 * @param {*} id 编号
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/user/getById', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 删除
 *
 * @param {*} id 编号
 * @returns
 */
function del(id) {
    return new Promise((resolve, reject) => {
        fetch('/user/delete', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 更新
 *
 * @param {*} id 编号
 * @param {*} name 用户名
 * @param {*} telephone 电话
 * @param {*} realName 真实姓名
 * @param {*} roleIds 角色ID
 * @param {*} status 状态 0-禁用 1-启用
 * @param {*} password 密码
 * @returns
 */
function update(data) {
    return new Promise((resolve, reject) => {
        postJson('/user/update', { id: data.id, name: data.name }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 新增
 *
 * @param {*} name 用户名
 * @param {*} telephone 电话
 * @param {*} realName 真实姓名
 * @param {*} roleIds 角色ID
 * @param {*} status 状态 0-禁用 1-启用
 * @param {*} password 密码
 * @returns
 */
function save(data) {
    return new Promise((resolve, reject) => {
        postJson('/user/saveAdmin', { name: data.name }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改当前用户密码
 *
 * @param {*} passwordOld 旧密码
 * @param {*} passwordNew 新密码
 * @returns
 */
function updatePassword(passwordOld, passwordNew) {
    return new Promise((resolve, reject) => {
        post('/user/updatePassword', {
            passwordOld,
            passwordNew,
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 重置面面
 * @param {*} userId  用户id
 * @param {*} password  面面
 * @returns 
 */
function resetPassword(userId, password) {
    return new Promise((resolve, reject) => {
        post('/user/resetPassword', { userId, password }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export default {
    login,
    prohibit,
    getById,
    del,
    update,
    save,
    pageListAdmin,
    updatePassword,
    resetPassword
}