/*
 * @Author: 齐白宇 
 * @Date: 2019-09-18 20:48:09 
 * @Last Modified by: 褚会茹
 * @Last Modified time: 2019-11-04 17:12:31
 */

import { fetch } from '@/utils/http.js'
import { postJson } from '@/utils/http.js'


/**
 * 全部
 *
 * @returns
 */
function getAll(name) {
    return new Promise((resolve, reject) => {
        fetch('/role/getAll', {
            params: {
                name
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 后台角色
 *
 * @returns
 */
function getForAdmin() {
    return new Promise((resolve, reject) => {
        fetch('/role/getForAdmin', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 查询角色详情
 *
 * @param {*} id 编号
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/role/getById', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 获取所有权限
 *
 * @returns
 */
function getAllClaims(type) {
    return new Promise((resolve, reject) => {
        fetch('/role/getAllClaims', {
            params: {
                type: type
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 新增
 *
 * @param {*} name 名称
 * @param {*} description 描述
 * @param {*} authorize 权限逗号分隔
 * @param {*} status 状态
 * @param {*} admin 前台后台 
 * @returns
 */
function save(name, description, authorize, status, admin) {
    return new Promise((resolve, reject) => {
        postJson('/role/save', { name }, {
            name,
            description,
            authorize,
            status,
            admin,
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 更新 
 *
 * @param {*} id 编号
 * @param {*} name 名称
 * @param {*} description 描述
 * @param {*} authorize 权限逗号分隔
 * @param {*} status 状态
 * @param {*} admin 前台后台
 * @returns
 */
function update(id, name, description, authorize, status, admin) {
    return new Promise((resolve, reject) => {
        postJson('/role/update', { id, name }, {
            id,
            name,
            description,
            authorize,
            status,
            admin,
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 * 删除
 *
 * @param {*} id
 * @returns
 */
function del(id) {
    return new Promise((resolve, reject) => {
        fetch('/role/delete', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改状态
 *
 * @param {*} ids 编号，可以用逗号隔开
 * @param {*} status 状态:0—禁用 ,1—启用
 * @returns
 */
function chanageStatus(ids, status) {
    return new Promise((resolve, reject) => {
        fetch('/role/updateStatus', {
            params: {
                status,
                ids,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 查询前台角色
 */
function getRoleForFront() {
    return new Promise((resolve, reject) => {
        fetch('/role/getRoleForFront', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


export default {
    getAll,
    getById,
    getAllClaims,
    save,
    update,
    del,
    chanageStatus,
    getForAdmin,
    getRoleForFront
}