 <template>
  <a-button
    v-if="hasAuth"
    :type="type"
    :block="block"
    :size="size"
    :shape="shape"
    :icon="icon"
    :disabled="dis"
    :loading="loading"
    @click="clickHandler"
    >{{ name }}</a-button
  >
</template>

<script>
export default {
  props: {
    type: String,
    size: String,
    shape: String,
    icon: String,
    name: String,
    roles: {
      type: Array,
      default: () => [],
    },
    allInRoles: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dis: this.disabled,
      hasAuth: true,
    };
  },
  mounted() {
    //获取当前按钮权限
    let btnAuths = this.roles;
    if (btnAuths.length > 0) {
      this.hasAuth = false;
      //获取本地个人权限
      let userAuths = localStorage.getItem(this.$msg.user.authority).split(",");
      let isPass = 0;
      //判断按钮是否用户的权限
      for (let i = 0; i < btnAuths.length; i++) {
        if (userAuths.indexOf(btnAuths[i]) > -1 || btnAuths[i].trim() == "")
          isPass++;
      }
      // 用户拥有权限即可操作
      if (!this.allInRoles && isPass > 0) {
        this.hasAuth = true;
      }
      // 用户必须拥有所有操作权限
      if (this.allInRoles && isPass == btnAuths.length) {
        this.hasAuth = true;
      }
    }
  },
  watch: {
    disabled(val) {
      this.dis = val;
    },
  },
  methods: {
    clickHandler() {
      this.$emit("click"); // 触发 `vclick` 事件
    },
  },
};
</script>