import { fetch } from '@/utils/http.js'
import { post } from '@/utils/http.js'

function getPaperPageList(pageNow, pageSize) {
    return new Promise((resolve, reject) => {
        fetch('/evaluating/getPaperPageList', {
            params: {
     
                pageNow: pageNow,
                pageSize: pageSize
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


function addOrUpdatePaper(paper) {
    return new Promise((resolve, reject) => {
        post('/evaluating/addOrUpdatePaper', 
            paper
        ).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


function getPaperById(id) {
    return new Promise((resolve, reject) => {
        fetch('/evaluating/getPaperById', {
            params: {
                id: id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


function updatePaperStatus(ids,status) {
    return new Promise((resolve, reject) => {
        fetch('/evaluating/updatePaperStatus', {
            params: {
                ids: ids,
                status: status,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function deletePaper(id) {
    return new Promise((resolve, reject) => {
        fetch('/evaluating/deletePaper', {
            params: {
                id: id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
export default {
    deletePaper,
    updatePaperStatus,
    getPaperById,
    addOrUpdatePaper,
    getPaperPageList
}