/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 贾晓红
 * @Last Modified time: 2019-12-30 09:37:01
 */

import { fetch, postJson, post } from '@/utils/http.js'



/**
 *考试分页列表
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @param {*} testName 考试名称
 * @param {*} examStatus 考试状态 0-未发布 1-进行中 2-已结束
 * @returns
 */
function getPageList(pageNow, pageSize, testName, examStatus) {
    return new Promise((resolve, reject) => {
        fetch('/examination/getPageList', {
            params: {
                pageNow,
                pageSize,
                testName,
                examStatus,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 添加
 *
 * @param {*} testName 考试名称
 * @param {*} examMode 考试类型
 * @param {*} questionsCount 题目数量
 * @param {*} examPeople 考试人数
 * @param {*} testDescription 考试说明
 * @param {*} minute 时间限制
 * @param {*} frequency 允许考试次数
 * @param {*} passLine 及格分数线
 * @param {*} examStatus 考试状态
 * @param {*} isDeleted 是否删除
 * @param {*} courseIds 关联课程
 * @returns
 */
function saveOrUpdate(exam) {
    return new Promise((resolve, reject) => {
        postJson('/examination/saveOrUpdate', { id: exam.id, testName: exam.testName }, exam).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 详情
 * @param {*} id 
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/examination/getDetail', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *删除
 *
 * @param {*} id
 * @returns
 */
function removeById(id) {
    return new Promise((resolve, reject) => {
        fetch('/examination/delete', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改考试状态
 * @param {*} ids 
 * @param {*} status 
 * @returns 
 */
function updateStatus(ids, status) {
    return new Promise((resolve, reject) => {
        fetch('/examination/updateStatus', {
            params: {
                ids,
                status
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *获取考试分数列表
 *
 * @param {*} examId 考试id
 * @param {*} userId 学生id
 * @returns
 */
function getTrainStudentExamScore(pageNow, pageSize, examId, name, startTime, endTime) {
    return new Promise((resolve, reject) => {
        fetch('/examination/getTrainStudentExamScore', {
            params: {
                pageNow,
                pageSize,
                examId,
                name,
                startTime,
                endTime
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 设置考试或证书条件
 * @param {*} id 考试id 或 证书id
 * @param {*} dataType 1 考试 2 证书
 * @param {*} trigger 
 * @returns 
 */
function setTrigger(id, dataType, trigger, selectCourses) {
    return new Promise((resolve, reject) => {
        postJson('/examination/setTrigger', { id, dataType, selectCourses }, trigger).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 查询考试或证书条件
 * @param {*} id  考试id 或 证书id
 * @param {*} dataType 1 考试 2 证书
 * @returns 
 */
function getTrigger(id, dataType) {
    return new Promise((resolve, reject) => {
        fetch('/examination/getTrigger', {
            params: {
                id, dataType
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 查询考试列表
 * @param {*} examIds 
 * @returns 
 */
function getExamListByIds(examIds) {
    return new Promise((resolve, reject) => {
        fetch('/examination/getExamListByIds', {
            params: {
                examIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 初始化学员考试记录
 * @param {*} examinationId 
 * @param {*} studentIds 
 * @returns 
 */
function saveStudentExamRecord(examinationId, studentIds) {
    return new Promise((resolve, reject) => {
        post('/examination/saveStudentExamRecord', {
            examinationId, studentIds
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    getPageList,
    saveOrUpdate,
    getById,
    removeById,
    updateStatus,
    getTrainStudentExamScore,
    setTrigger,
    getTrigger,
    saveStudentExamRecord,
    getExamListByIds,
}