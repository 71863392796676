/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 褚会茹
 * @Last Modified time: 2019-12-28 09:24:45
 */

import { fetch, postJson } from '@/utils/http.js'



/**
 * 试题类型树形
 *
 * @returns
 */
function getTreeAuth() {
    return new Promise((resolve, reject) => {
        fetch('/questionCategory/getTreeAuth', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getTree() {
    return new Promise((resolve, reject) => {
        fetch('/questionCategory/getTree', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 试题类型全部
 *
 * @returns
 */
function getAll() {
    return new Promise((resolve, reject) => {
        fetch('/questionCategory/getAll', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function saveOrUpdate(questionCategory) {
    return new Promise((resolve, reject) => {
        postJson('/questionCategory/saveOrUpdate', { id: questionCategory.id, name: questionCategory.name }, questionCategory).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function deleteById(id) {
    return new Promise((resolve, reject) => {
        fetch('/questionCategory/delete', { params: { id } }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/questionCategory/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getParentIds(id) {
    return new Promise((resolve, reject) => {
        fetch('/questionCategory/getParentIds', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function getChildIds(id) {
    return new Promise((resolve, reject) => {
        fetch('/questionCategory/getChildIds', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    getTreeAuth,
    getTree,
    getAll,
    saveOrUpdate,
    deleteById,
    getById,
    getParentIds,
    getChildIds
}