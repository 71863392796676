/*
 * @Author: 齐白宇 
 * @Date: 2019-04-01 09:25:16 
 */

import axios from 'axios';
import VueCookies from 'vue-cookies'
import msg from '@/utils/msg.js'
import router from '@/router/index.js'
import 'ant-design-vue/dist/antd.css'

axios.defaults.timeout = 50000;
axios.defaults.baseURL = '';

export var isDev = process.env.NODE_ENV === 'development';

var BASE_URL = 'http://localhost:8083'; // 本地

// var BASE_URL = 'https://api.hongdw.com/admin';
// var PRO_URL = 'https://api.hongdw.com/admin';
var PRO_URL = 'https://api.hongdw.com/admin';
// var PRO_URL = 'http://47.112.147.238:20010';

// var BASE_URL = 'https://zxpxapi.kuguanyun.cn/admin'; // 测试
// var PRO_URL = 'https://zxpxapi.kuguanyun.cn/admin'; // 测试

export function download(url, data = {}) {

    if (data) {
        var arr = [];
        for (let it in data) {
            if (data[it]) {
                arr.push(encodeURIComponent(it) + '=' + encodeURIComponent(data[it]));
            }
        }
        if (arr.length > 0) {
            url += "?" + arr.join("&");
        }
        if (url.indexOf("?") != -1) {
            url += "&";
        } else {
            url += "?";
        }
        if (VueCookies.get(msg.user.Authentication)) {
            url += 'Authentication=' + VueCookies.get(msg.user.Authentication) + "&remoteType=operationsystem";
        } else {
            url += "&remoteType=operationsystem";
        }

    }
    return handleUrl(url);
}


/**
 *  添加请求头(带HTTP时不添加)
 *
 * @param {*} url 请求地址
 * @returns
 */
export function handleUrl(url) {
    if (url.indexOf('http') == -1) {
        url = (isDev ? BASE_URL : PRO_URL) + url
    }

    return url
}

//http request 拦截器
axios.interceptors.request.use(
    config => {
        config.url = handleUrl(config.url);
        if (config.params && config.params.requestType == "json") {
            config.headers = {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        } else {
            config.headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }


        if (VueCookies.get(msg.user.Authentication) != null) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            if (config.method == "get" && config.url.indexOf('security/login/userLogin') == -1) {
                config.params.Authentication = VueCookies.get(msg.user.Authentication);
            } else {
                if (config.params && config.params.requestType == "json") {
                    config.params.Authentication = VueCookies.get(msg.user.Authentication)
                } else {
                    config.data = config.data + "Authentication=" + VueCookies.get(msg.user.Authentication);
                }

            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


//http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.errorCode == msg.responseCode.needLogin) {
            VueCookies.remove(msg.user.Authentication) //移除登录凭证
            VueCookies.remove(msg.user.authority) //移除用户权限
            router.push({
                path: "/login",
                querry: {
                    redirect: router.currentRoute.fullPath
                } //从哪个页面跳转
            })
        } else {
            return response;
        }
    },
    error => {
        return Promise.reject(error)
    }
)


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
    params.params.remoteType = 'operationsystem';
    return new Promise((resolve, reject) => {
        axios.get(url, params)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}



/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
    data.remoteType = 'operationsystem';
    return new Promise((resolve, reject) => {
        axios.post(url, transformRequest(data))
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}


export function postJson(url, params = {}, data = {}) {
    params.remoteType = 'operationsystem';
    params.requestType = 'json'
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            params: params,
        })
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

export function postJsonToBlob(url, params = {}, data = {}) {
    params.remoteType = 'operationsystem';
    params.requestType = 'json'
    return new Promise((resolve, reject) => {
        axios.post(url, data, { params: params, responseType: "arraybuffer" })
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 *  对数据编码
 *
 * @param {*} data 
 * @returns
 */
function transformRequest(data) {
    let ret = ''
    for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
    }
    return ret
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}