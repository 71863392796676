/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2020-04-03 13:49:13
 */

import {
    fetch,
    postJson,
    download
} from '@/utils/http.js'


/**
 * 获取职称评审列表
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @returns
 */
function getReviewTitleList(pageNow, pageSize, name, workUnit) {
    return new Promise((resolve, reject) => {
        fetch('/reviewTitle/getReviewTitleList', {
            params: {
                pageNow,
                pageSize,
                name,
                workUnit
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *根据id查询详情
 *
 * @param {*} id
 * @returns
 */
function getReviewTitle(id) {
    return new Promise((resolve, reject) => {
        fetch('/reviewTitle/getReviewTitle', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function deleteReviewTitle(id) {
    return new Promise((resolve, reject) => {
        fetch('/reviewTitle/deleteReviewTitle', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 新增或者修改
 * @param {*} reviewTitle 
 * @returns 
 */
function saveOrUpdate(reviewTitle) {
    return new Promise((resolve, reject) => {
        postJson('/reviewTitle/saveReviewTitle', {}, reviewTitle).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 回复
 * @param {*} reviewTitle 
 * @returns 
 */
function reply(reviewTitle) {
    return new Promise((resolve, reject) => {
        postJson('/reviewTitle/reply', { id: reviewTitle.id }, reviewTitle).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 获取附件配置
 * @returns 
 */
function getReviewTitleConfig() {
    return new Promise((resolve, reject) => {
        fetch('/reviewTitle/getReviewTitleConfig', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 保存附件配置
 * @param {*} config 
 * @returns 
 */
function setReviewTitleConfig(config) {
    return new Promise((resolve, reject) => {
        postJson('/reviewTitle/setReviewTitleConfig', {}, config).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function exportDatas(data) {
    return download("/reviewTitle/exportData", data);
}



export default {
    getReviewTitleList,
    getReviewTitle,
    saveOrUpdate,
    reply,
    getReviewTitleConfig,
    setReviewTitleConfig,
    exportDatas,
    deleteReviewTitle
}