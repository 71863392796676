/*
 * @Author: 齐白宇 
 * @Date: 2019-09-20 09:02:01 
 * @Last Modified by: 齐白宇
 * @Last Modified time: 2019-09-20 09:42:54
 */

import { fetch, post, postJson } from '@/utils/http.js'

/**
 * 分页列表
 *
 * @param {*} pageNow 当先页数;默认为1
 * @param {*} pageSize 每页条数;默认为10
 * @returns
 */
function pageList(pageNow, pageSize) {
    return new Promise((resolve, reject) => {
        fetch('/notice/getPageList', {
            params: {
                pageNow,
                pageSize
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 查询通知详情
 *
 * @param {*} id 编号
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/notice/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 删除通知
 *
 * @param {*} id 编号
 * @returns
 */
function del(ids) {
    return new Promise((resolve, reject) => {
        fetch('/notice/deleteByIds', {
            params: {
                ids
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 新增
 *
 * @param {*} title 标题
 * @param {*} content 内容
 * @returns
 */
function save(title, content) {
    return new Promise((resolve, reject) => {
        post('/notice/save', {
            title,
            content,
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 更新
 *
 * @param {*} id 编号
 * @param {*} title 标题
 * @param {*} content 内容
 * @returns
 */
function update(id, title, content) {
    return new Promise((resolve, reject) => {
        postJson('/notice/update', { id, title, }, { id, title, content, }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    pageList,
    getById,
    del,
    save,
    update,
}