/*
 * @Author: 齐白宇 
 * @Date: 2019-09-27 16:50:29 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2022-05-11 11:48:53
 */

import { fetch, postJson } from '@/utils/http.js'


/**
 * 获取网站设置
 */
function getPortalSetting() {
    return new Promise((resolve, reject) => {
        fetch('/setting/getPortalSetting', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存网站设置
 */
function setPortalSetting(portalSetting) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setPortalSetting', {}, portalSetting).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 获取第三方设置
 */
function getThirdConfig() {
    return new Promise((resolve, reject) => {
        fetch('/setting/getThirdSetting', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存支付宝支付配置
 */
function setAlipayConfig(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setAliPaySetting', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存微信支付配置
 */
function setWechatConfig(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setWechatPaySetting', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存微信授权配置
 */
function setWxMpConfig(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setWxMpSetting', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 保存QQ授权配置
 */
function setQQConfig(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setQQSetting', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存短信配置
 */
function setSmsConfig(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setSmsConfig', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存腾讯实时音视频配置
 */
function setTencentTRTCConfig(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setTencentTRTCConfig', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 获取腾讯云点播转自适应码流配置
 */
function getTencentAdaptTranscode() {
    return new Promise((resolve, reject) => {
        fetch('/setting/getTencentAdaptTranscode', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 保存腾讯云直播配置
 */
function setTencentYunLiveConfig(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setTencentYunLiveConfig', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存腾讯云配置
 */
function setTencentVideoConfig(tencentVideoConfig) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setTencentVideoConfig', {}, tencentVideoConfig).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 获取系统参数设置
 */
function getSystemSetting() {
    return new Promise((resolve, reject) => {
        fetch('/setting/getSystemSetting', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存用户角色配置
 */
function setTrainSetting(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setTrainSetting', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存课程配置
 */
function setCourseSetting(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setCourseSetting', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 * 保存资源配置
 */
function setResourceSetting(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setResourceSetting', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存证书配置
 */
function setCertificateSetting(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setCertificateSetting', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 保存账户配置
 */
function setMemberSetting(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setMemberSetting', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 保存订单配置
 */
function setOrderSetting(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setOrderSetting', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function setAppPushConfig(data) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setAppPushConfig', {}, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 获取课程学时配置
 */
function getCourseHourTypes() {
    return new Promise((resolve, reject) => {
        fetch('/setting/getCourseHourTypes', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    getThirdConfig,
    setAppPushConfig,
    setWxMpConfig,
    setWechatConfig,
    setAlipayConfig,
    setSmsConfig,
    getPortalSetting,
    setPortalSetting,
    getSystemSetting,
    setTrainSetting,
    setCourseSetting,
    setResourceSetting,
    setCertificateSetting,
    setTencentTRTCConfig,
    setTencentYunLiveConfig,
    setTencentVideoConfig,
    getTencentAdaptTranscode,
    setQQConfig,
    getCourseHourTypes,
    setMemberSetting,
    setOrderSetting
}