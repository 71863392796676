<template>
  <div>
    <div class="top_statistic">
      <a-row :gutter="26" style="backgroud: #333">
        <!-- <a-col :span="4">
          <a-card>
            <a-statistic :value="agencyData.agencyCount">
              <span slot="title" style="color: #5541d4">基地数量</span>
            </a-statistic>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card>
            <a-statistic :value="agencyData.trainProjectCount">
              <span slot="title" style="color: #415fd8">培训项目</span>
            </a-statistic>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card>
            <a-statistic :value="agencyData.trainClassCount">
              <span slot="title" style="color: #d82f32">培训班数量</span>
            </a-statistic>
          </a-card>
        </a-col> -->
        <a-col :span="8">
          <a-card>
            <a-statistic :value="agencyData.studentCount">
              <span slot="title" style="color: #493ed9">学员数量</span>
            </a-statistic>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card>
            <a-statistic :value="agencyData.courseCount">
              <span slot="title" style="color: #e6792f">课程数量</span>
            </a-statistic>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card>
            <a-statistic :value="agencyData.subjectCount">
              <span slot="title" style="color: #2a4ad1">题库数量</span>
            </a-statistic>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <div class="middle-statistics"></div>
    <div class="bottom_statistics">
      <a-row :gutter="18" class="custom-col">
        <a-col :span="5">
          <a-card class="color-one" @click="gotoPath('/course/courseList')">
            <i class="iconfont icon-kecheng"></i>
            <div class="title">课程资源</div>
          </a-card>
        </a-col>
        <a-col :span="5">
          <a-card
            class="color-two"
            @click="gotoPath('/examination/subjectlist')"
          >
            <i class="iconfont icon-tiku"></i>
            <div class="title">题库资源</div>
          </a-card>
        </a-col>
        <a-col :span="5">
          <a-card
            class="color-three"
            @click="gotoPath('/resource/resourceList')"
          >
            <i class="iconfont icon-ziliaoku"></i>
            <div class="title">资料管理</div>
          </a-card>
        </a-col>
        <a-col :span="5">
          <a-card class="color-two" @click="gotoPath('/course/broadcastlist')">
            <i class="iconfont icon-zhibo"></i>
            <div class="title">直播管理</div>
          </a-card>
        </a-col>
        <!-- <a-col :span="4">
          <a-card
            class="color-four"
            @click="gotoPath('/trainAgency/trainAgencyList')"
          >
            <i class="iconfont icon-jidi"></i>
            <div class="title">基地管理</div>
          </a-card>
        </a-col> -->
        <a-col :span="5">
          <a-card
            class="color-five"
            @click="gotoPath('/certificate/certificateList')"
          >
            <i class="iconfont icon-zhengshu"></i>
            <div class="title">证书管理</div>
          </a-card>
        </a-col>
        <!-- <a-col :span="4">
          <a-card class="color-five" @click="gotoPath('/projects/projectList')">
            <i class="iconfont icon-peixun"></i>
            <div class="title">培训项目</div>
          </a-card>
        </a-col> -->
      </a-row>
      <a-row :gutter="18" class="custom-col">
        <!-- <a-col :span="7">
          <a-card class="color-four" @click="gotoPath('/train/trainClassList')">
            <i class="iconfont icon-banji"></i>
            <div class="title">培训班级</div>
          </a-card>
        </a-col> -->
        <!-- <a-col :span="4">
          <a-card
            class="color-five"
            @click="gotoPath('/certificate/certificateList')"
          >
            <i class="iconfont icon-zhengshu"></i>
            <div class="title">证书管理</div>
          </a-card>
        </a-col> -->
        <a-col :span="8">
          <a-card class="color-one" @click="gotoPath('/expert/expertList')">
            <i class="iconfont icon-team-fill"></i>
            <div class="title">学员管理</div>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card
            class="color-three"
            @click="gotoPath('/cms/successArticlelist')"
          >
            <i class="iconfont icon-neirong"></i>
            <div class="title">内容管理</div>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card class="color-two" @click="gotoPath('/user/rolelist')">
            <i class="iconfont icon-tubiao01"></i>
            <div class="title">系统设置</div>
          </a-card>
        </a-col>
      </a-row>
      <!-- <a-row :gutter="22">
        <a-col :span="8">
          <a-card
            class="color-three"
            @click="gotoPath('/studentArchive/StudentList')"
          >
            <i class="iconfont icon-dangan1"></i>
            <div class="title">档案管理</div>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card class="color-five" @click="gotoPath('/statistics')">
            <i class="iconfont icon-shuju"></i>
            <div class="title">数据分析</div>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card class="color-two" @click="gotoPath('/user/rolelist')">
            <i class="iconfont icon-tubiao01"></i>
            <div class="title">系统设置</div>
          </a-card>
        </a-col>
      </a-row> -->
    </div>
  </div>
</template>
<script>
let _this;
export default {
  name: "Home",
  data() {
    return {
      agencyData: {
        subjectCount: 0,
        courseCount: 0,
        studentCount: 0,
      },
    };
  },
  mounted() {
    _this = this;
    _this.getStatistics();
    // _this.goTo();
  },
  methods: {
    fromFather() {
      this.$emit("save");
    },
    gotoPath(path) {
      this.$router.push(path);
    },
    //人数
    getStatistics() {
      _this.$api.statistics
        .getStatistics()
        .then((res) => {
          _this.agencyData = res.data;
        })
        .catch(() => {
          this.isloading = false;
        });
    },
  },
};
</script>
<style>
.top_statistic {
  padding: 32px;
}
.top_statistic .ant-card {
  border-radius: 8px;
  box-shadow: 0px 0px 38px 0px rgba(188, 193, 197, 0.49);
}
.top_statistic .ant-col-4 .ant-statistic-title {
  font-size: 18px;
}
.top_statistic .ant-col-4 .ant-statistic-content {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
}
.middle-statistics {
  height: 16px;
  width: 100%;
  background: #eff2f5;
}
.bottom_statistics {
  padding: 32px;
}
.bottom_statistics .custom-col {
  margin-bottom: 15px;
}
.bottom_statistics .ant-card {
  border-radius: 8px;
  height: 170px;
  cursor: pointer;
}
.bottom_statistics .ant-card .ant-card-body {
  text-align: center;
}
.bottom_statistics .ant-card .iconfont {
  color: #fff;
  font-size: 48px;
}
.bottom_statistics .ant-card .title {
  color: #fff;
  font-size: 24px;
  font-family: "Source Han Sans CN";
}
.bottom_statistics .custom-col .ant-col-4 {
  width: 15%;
}
.bottom_statistics .custom-col .ant-col-7 {
  width: 30%;
}
.bottom_statistics .color-one {
  background: linear-gradient(48deg, #fd8689 0%, #ff676a 100%);
}
.bottom_statistics .color-two {
  background: linear-gradient(48deg, #348cf5 0%, #61c1f7 100%);
}
.bottom_statistics .color-three {
  background: linear-gradient(48deg, #7a71fd 0%, #9f8dff 100%);
}
.bottom_statistics .color-four {
  background: linear-gradient(48deg, #fb9655 0%, #ffad62 100%);
}
.bottom_statistics .color-five {
  background: linear-gradient(48deg, #506ff0 0%, #6b88ff 100%);
}
@media screen and (max-width: 1600px) {
  .top_statistic .ant-col-4 .ant-statistic-title {
    font-size: 16px;
  }
  .top_statistic .ant-col-4 .ant-statistic-content {
    font-size: 24px;
  }
  .bottom_statistics .ant-card .iconfont {
    font-size: 36px;
  }
  .bottom_statistics .ant-card .title {
    font-size: 18px;
  }
  .bottom_statistics .ant-card {
    height: 120px;
  }
}
.ant-col-5 {
  width: 20%;
}
.ant-statistic-title {
  font-size: 18px;
}
.ant-statistic-content-value-int {
  font-size: 36px;
  display: inline-block;
  padding-left: 20%;
}
</style>