/*
 * @Author: 齐白宇 
 * @Date: 2019-04-01 11:23:54 
 */
import Vue from 'vue'
import Router from 'vue-router'
import VueCookies from 'vue-cookies'
import rightMain from "@/components/basic/rightMain.vue"; //右侧主题(第一菜单需要)
// 引入组件
import notFound from "@/views/common/notFound.vue"; //404
import home from "@/views/common/homes.vue"; //首页
import login from "@/views/login.vue"; //登录
import main from "@/views/main.vue"; //后台主界面
import msg from '@/utils/msg.js'
import store from "@/store"
import commen from '@/utils/commen.js'


Vue.use(Router)

const router = new Router({
    routes: [{
        path: "/login",
        name: "login",
        meta: {
            name: "登录",
            leftMenu: false,
            icon: "align-left"
        },
        component: login
    },
    {
        path: "/",
        name: "main",
        meta: {
            requireAuth: true,
            name: "管理系统",
            leftMenu: false,
            icon: "shop",
        },
        component: main,
        children: [
            {
                path: "/",
                name: "home",
                meta: {
                    requireAuth: true,
                    name: "主页",
                    leftMenu: true,
                    icon: "icon-home"
                },
                component: home
            },
            {
                path: "/resourceManage",
                name: "resourceManage",
                meta: {
                    requireAuth: true,
                    name: "资源管理",
                    leftMenu: true,
                    icon: "icon-ziyuanguanli"
                },
                component: rightMain,
                children: [
                    {
                        path: "/course",
                        name: "course",
                        meta: {
                            requireAuth: true,
                            name: "课程管理",
                            leftMenu: true,
                            icon: "snippets"
                        },
                        component: rightMain,
                        children: [
                            {
                                path: "/course/courseList",
                                name: "courseList",
                                meta: {
                                    requireAuth: true,
                                    name: "录播课程",
                                    icon: "play-square",
                                    leftMenu: true,
                                    roles: [],
                                },
                                component: resolve => require(['@/views/course/CourseList.vue'], resolve),
                            },
                            {
                                path: "/course/broadcastlist",
                                name: "broadcastlist",
                                meta: {
                                    requireAuth: true,
                                    name: "直播课程",
                                    leftMenu: true,
                                    icon: "video-camera",
                                    roles: [],
                                },
                                component: resolve => require(['@/views/course/LiveCourseList.vue'], resolve),
                            },
                            {
                                path: "/course/courseTypelist",
                                name: "courseTypelist",
                                meta: {
                                    requireAuth: true,
                                    name: "课程分类",
                                    leftMenu: true,
                                    icon: "profile",
                                    roles: [],
                                },
                                component: resolve => require(['@/views/course/CourseTypeList.vue'], resolve),
                            },
                        ]
                    },
                    {
                        path: "/resource",
                        name: "resource",
                        meta: {
                            requireAuth: true,
                            name: "资料管理",
                            leftMenu: true,
                            icon: "snippets"
                        },
                        component: rightMain,
                        children: [
                            {
                                path: "/resource/resourceList",
                                name: "resourceList",
                                meta: {
                                    requireAuth: true,
                                    name: "资料管理",
                                    icon: "play-square",
                                    leftMenu: true,
                                    roles: [],
                                },
                                component: resolve => require(['@/views/resource/ResourceList.vue'], resolve),
                            },
                            {
                                path: "/resource/resourceTypeList",
                                name: "resourceTypeList",
                                meta: {
                                    requireAuth: true,
                                    name: "资料分类",
                                    leftMenu: true,
                                    icon: "profile",
                                    roles: [],
                                },
                                component: resolve => require(['@/views/resource/ResourceTypeList.vue'], resolve),
                            },
                        ]
                    },
                    {
                        path: "/examination",
                        name: "examination",
                        meta: {
                            requireAuth: true,
                            name: "考试管理",
                            leftMenu: true,
                            icon: "highlight"
                        },
                        component: rightMain,
                        children: [
                            {
                                path: "/examination/typelist",
                                name: "typelist",
                                meta: {
                                    requireAuth: true,
                                    name: "题目分类",
                                    icon: "hdd",
                                    leftMenu: true,
                                    roles: [],
                                },
                                component: resolve => require(['@/views/examination/SubjectTypeList.vue'], resolve),
                            },
                            {
                                path: "/examination/subjectlist",
                                name: "subjectlist",
                                meta: {
                                    requireAuth: true,
                                    name: "题库列表",
                                    icon: "hdd",
                                    leftMenu: true,
                                    roles: [],
                                },
                                component: resolve => require(['@/views/examination/subjectlist.vue'], resolve),
                            },

                            {
                                path: "/examination/examinationlist",
                                name: "examinationlist",
                                meta: {
                                    requireAuth: true,
                                    name: "考试列表",
                                    leftMenu: true,
                                    icon: "unordered-list",
                                    roles: [],
                                },
                                component: resolve => require(['@/views/examination/examinationlist.vue'], resolve),
                            },
                            // {
                            //     path: "/subjectpractice/subjectPractice",
                            //     name: "subjectpracticelist",
                            //     meta: {
                            //         requireAuth: true,
                            //         name: "答题活动",
                            //         leftMenu: true,
                            //         icon: "form",
                            //         roles: [],
                            //     },
                            //     component: resolve => require(['@/views/subjectpractice/SubjectPracticeList.vue'], resolve),
                            // },
                        ]
                    },
                    // {
                    //     path: "/evaluating",
                    //     name: "evaluating",
                    //     meta: {
                    //         requireAuth: true,
                    //         name: "测评管理",
                    //         leftMenu: true,
                    //         icon: "highlight"
                    //     },
                    //     component: rightMain,
                    //     children: [
                    //         {
                    //             path: "/evaluating/list",
                    //             name: "list",
                    //             meta: {
                    //                 requireAuth: true,
                    //                 name: "测评试卷",
                    //                 icon: "hdd",
                    //                 leftMenu: true,
                    //                 roles: [],
                    //             },
                    //             component: resolve => require(['@/views/evaluating/list.vue'], resolve),
                    //         },
                    //         {
                    //             path: "/evaluating/add",
                    //             name: "evaluatingadd",
                    //             meta: {
                    //                 requireAuth: true,
                    //                 name: "添加测评",
                    //                 icon: "hdd",
                    //                 leftMenu: true,
                    //                 roles: [],
                    //             },
                    //             component: resolve => require(['@/views/evaluating/add.vue'], resolve),
                    //         },
                    //     ]
                    // },
                    {
                        path: "/certificate",
                        name: "certificate",
                        meta: {
                            requireAuth: true,
                            name: "证书管理",
                            leftMenu: true,
                            icon: "solution"
                        },
                        component: rightMain,
                        children: [
                            {
                                path: "/certificate/certificateList",
                                name: "certificate",
                                meta: {
                                    requireAuth: true,
                                    name: "证书设置",
                                    icon: "trophy",
                                    leftMenu: true,
                                },
                                component: resolve => require(['@/views/certificate/certificateList.vue'], resolve),
                            },
                            {
                                path: "/certificate/certificateReords",
                                name: "certificateReords",
                                meta: {
                                    requireAuth: true,
                                    name: "证书记录",
                                    icon: "form",
                                    leftMenu: true,
                                },
                                component: resolve => require(['@/views/certificate/CertificateRecordList.vue'], resolve),
                            },

                        ]
                    },

                ]
            },

            {
                path: "/cms",
                name: "cms",
                meta: {
                    requireAuth: true,
                    name: "内容管理",
                    leftMenu: true,
                    icon: "icon-neirongguanli"
                },
                component: rightMain,
                children: [

                    {
                        path: "/cms/successArticlelist",
                        name: "successArticlelist",
                        meta: {
                            requireAuth: true,
                            name: "内容列表",
                            icon: "check-square",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/cms/successArticlelist.vue'], resolve),
                    },
                    {
                        path: "/cms/articleTypelist",
                        name: "articleTypelist",
                        meta: {
                            requireAuth: true,
                            name: "内容分类",
                            icon: "profile",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/cms/articleTypelist.vue'], resolve),
                    },
                    // {
                    //     path: "/system/noticelist",
                    //     name: "noticelist",
                    //     meta: {
                    //         requireAuth: true,
                    //         name: "通知管理",
                    //         icon: "alert",
                    //         leftMenu: true,
                    //     },
                    //     component: resolve => require(['@/views/system/noticelist.vue'], resolve),
                    // },
                    {
                        path: "/system/messagelist",
                        name: "messagelist",
                        meta: {
                            requireAuth: true,
                            name: "消息管理",
                            icon: "message",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/system/messagelist.vue'], resolve),
                    },

                    {
                        path: "/advert",
                        name: "advert",
                        meta: {
                            requireAuth: true,
                            name: "广告管理",
                            leftMenu: true,
                            icon: "sound"
                        },
                        component: rightMain,
                        children: [
                            {
                                path: "/advert/advertzonelist",
                                name: "advertzonelist",
                                meta: {
                                    requireAuth: true,
                                    name: "广告位管理",
                                    icon: "database",
                                    leftMenu: true,
                                },
                                component: resolve => require(['@/views/advert/advertzonelist.vue'], resolve),
                            },
                            {
                                path: "/advert/advertmaterial",
                                name: "advertmaterial",
                                meta: {
                                    requireAuth: true,
                                    name: "广告服务",
                                    icon: "sound",
                                    leftMenu: true,
                                },
                                component: resolve => require(['@/views/advert/advertmaterial.vue'], resolve),
                            },
                            {
                                path: "/advert/friendlink",
                                name: "friendlink",
                                meta: {
                                    requireAuth: true,
                                    name: "友情链接",
                                    icon: "link",
                                    leftMenu: true,
                                },
                                component: resolve => require(['@/views/advert/friendlink.vue'], resolve),
                            },
                        ]
                    },
                    {
                        path: "/comments/comments",
                        name: "comments",
                        meta: {
                            requireAuth: true,
                            name: "评论管理",
                            icon: "message",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/comments/comments.vue'], resolve),
                    },

                ]
            },
            {
                path: "/business",
                name: "business",
                meta: {
                    requireAuth: true,
                    name: "运营管理",
                    leftMenu: true,
                    icon: "icon-yunying"
                },
                component: rightMain,
                children: [
                    {
                        path: "/recruitPractice",
                        name: "recruitPracticeList",
                        meta: {
                            requireAuth: true,
                            name: "实习就业",
                            icon: "experiment",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/recruit/recruitPracticeList.vue'], resolve),
                    },
                    {
                        path: "/recruitRequire",
                        name: "recruitRequireList",
                        meta: {
                            requireAuth: true,
                            name: "招聘需求",
                            icon: "file-search",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/recruit/recruitRequireList.vue'], resolve),
                    },
                    {
                        path: "/reviewTitle",
                        name: "reviewTitleList",
                        meta: {
                            requireAuth: true,
                            name: "职称评审",
                            icon: "read",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/reviewTitle/reviewTitleList.vue'], resolve),
                    },
                    // {
                    //     path: "/trainAgency/trainAgencyList",
                    //     name: "trainAgencyList",
                    //     meta: {
                    //         requireAuth: true,
                    //         name: "培训机构",
                    //         icon: "unordered-list",
                    //         leftMenu: true,
                    //     },
                    //     component: resolve => require(['@/views/trainAgency/trainAgencyList.vue'], resolve),
                    // },
                    // {
                    //     path: "/projects/projectList",
                    //     name: "projectList",
                    //     meta: {
                    //         requireAuth: true,
                    //         name: "培训项目",
                    //         icon: "box-plot",
                    //         leftMenu: true,
                    //     },
                    //     component: resolve => require(['@/views/projects/projectList.vue'], resolve),
                    // },
                    // {
                    //     path: "/train/trainClassList",
                    //     name: "trainClass",
                    //     meta: {
                    //         requireAuth: true,
                    //         name: "培训班",
                    //         icon: "team",
                    //         leftMenu: true,
                    //     },
                    //     component: resolve => require(['@/views/train/TrainClassList.vue'], resolve),
                    // },
                    {
                        path: "/statistics/courseStatistics",
                        name: "courseStatistics",
                        meta: {
                            requireAuth: true,
                            name: "课程统计",
                            icon: "book",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/statistics/courseStatistics.vue'], resolve),
                    },
                    {
                        path: "/statistics/studyStatistics",
                        name: "studyStatistics",
                        meta: {
                            requireAuth: true,
                            name: "学习统计",
                            icon: "book",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/statistics/studyStatistics.vue'], resolve),
                    },
                    {
                        path: "/order/orderList",
                        name: "orderList",
                        meta: {
                            requireAuth: true,
                            name: "订单列表",
                            icon: "unordered-list",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/order/orderList.vue'], resolve),
                    },
                ]
            },
            {
                path: "/expert",
                name: "expert",
                meta: {
                    requireAuth: true,
                    name: "用户管理",
                    leftMenu: true,
                    icon: "icon-yonghu"
                },
                component: rightMain,
                children: [
                    {
                        path: "/expert/expertlist",
                        name: "expertlist",
                        meta: {
                            requireAuth: true,
                            name: "学员用户列表",
                            leftMenu: true,
                            icon: "user",
                            roles: [],
                        },
                        component: resolve => require(['@/views/expert/StudentList.vue'], resolve),
                    },
                    {
                        path: "/expert/teacherlist",
                        name: "teacherlist",
                        meta: {
                            requireAuth: true,
                            name: "教师用户列表",
                            leftMenu: true,
                            icon: "user",
                            roles: [],
                        },
                        component: resolve => require(['@/views/expert/teacherlist.vue'], resolve),
                    },
                    // {
                    //     path: "/expert/companylist",
                    //     name: "companylist",
                    //     meta: {
                    //         requireAuth: true,
                    //         name: "企业用户列表",
                    //         leftMenu: true,
                    //         icon: "contacts",
                    //         roles: [],
                    //     },
                    //     component: resolve => require(['@/views/expert/companylist.vue'], resolve),
                    // },
                    {
                        path: "/expert/usergrouplist",
                        name: "usergrouplist",
                        meta: {
                            requireAuth: true,
                            name: "用户组列表",
                            leftMenu: true,
                            icon: "team",
                            roles: [],
                        },
                        component: resolve => require(['@/views/expert/usergrouplist.vue'], resolve),
                    },
                ]
            },
            // {
            //     path: "/archive",
            //     name: "archive",
            //     meta: {
            //         requireAuth: true,
            //         name: "档案管理",
            //         leftMenu: true,
            //         icon: "icon-dangan"
            //     },
            //     component: rightMain,
            //     children: [
            //         {
            //             path: "/studentArchive/StudentList",
            //             name: "studentArchives",
            //             meta: {
            //                 requireAuth: true,
            //                 name: "学员档案",
            //                 icon: "file-done",
            //                 leftMenu: true,
            //                 roles: [],
            //             },
            //             component: resolve => require(['@/views/studentArchive/StudentList.vue'], resolve),
            //         },
            //         {
            //             path: "/agency/AgencyList",
            //             name: "agencyArchives",
            //             meta: {
            //                 requireAuth: true,
            //                 name: "机构档案",
            //                 leftMenu: true,
            //                 icon: "file-done",
            //                 roles: [],
            //             },
            //             component: resolve => require(['@/views/agency/AgencyList.vue'], resolve),
            //         },
            //     ]
            // },
            {
                path: "/user",
                name: "user",
                meta: {
                    requireAuth: true,
                    name: "系统设置",
                    leftMenu: true,
                    icon: "icon-set-up"
                },
                component: rightMain,
                children: [
                    {
                        path: "/user/rolelist",
                        name: "rolelist",
                        meta: {
                            requireAuth: true,
                            name: "角色列表",
                            icon: "team",
                            leftMenu: true,
                            roles: [],
                        },
                        component: resolve => require(['@/views/user/rolelist.vue'], resolve),
                    },
                    {
                        path: "/user/userlist",
                        name: "userlist",
                        meta: {
                            requireAuth: true,
                            name: "账户列表",
                            leftMenu: true,
                            icon: "user",
                            roles: [],
                        },
                        component: resolve => require(['@/views/user/userlist.vue'], resolve),
                    },
                    {
                        path: "/system/dictionarylist",
                        name: "dictionarylist",
                        meta: {
                            requireAuth: true,
                            name: "数据字典",
                            icon: "database",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/system/dictionarylist.vue'], resolve),
                    },
                    {
                        path: "/system/parameter",
                        name: "parameter",
                        meta: {
                            requireAuth: true,
                            name: "参数设置",
                            icon: "setting",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/system/parameter.vue'], resolve),
                    },
                    {
                        path: "/system/websiteSetting",
                        name: "websiteSetting",
                        meta: {
                            requireAuth: true,
                            name: "网站设置",
                            icon: "money-collect",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/system/websiteSetting.vue'], resolve),
                    },
                    {
                        path: "/system/thirdconfig",
                        name: "thirdconfig",
                        meta: {
                            requireAuth: true,
                            name: "第三方设置",
                            icon: "setting",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/system/thirdconfig.vue'], resolve),
                    },
                    {
                        path: "/system/appVersion",
                        name: "appVersion",
                        meta: {
                            requireAuth: true,
                            name: "APP版本",
                            icon: "setting",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/system/appVersion.vue'], resolve),
                    },
                    {
                        path: "/system/log",
                        name: "log",
                        meta: {
                            requireAuth: true,
                            name: "系统日志",
                            icon: "fund",
                            leftMenu: true,
                        },
                        component: resolve => require(['@/views/system/logrecord.vue'], resolve),
                    }
                ]
            },

            {
                // 会匹配所有路径
                path: '*',
                name: "notFound",
                meta: {
                    name: "404",
                    leftMenu: false,
                    icon: "mail"
                },
                component: notFound
            }
        ]
    },

    ]
})

router.beforeEach((to, from, next) => {
    store.dispatch("breadcrumb/setBreadcrumb", commen.getThisBreadcrumbs(router.options.routes[1].children, to))
    if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
        if (VueCookies.get(msg.user.Authentication) != null) { // 判断当前的token是否存在
            next();
        } else {
            next({
                path: '/login',
            })
        }
    } else {
        next();
    }
})



export default router;