/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 褚会茹
 * @Last Modified time: 2022-01-12 17:27:13
 */

// import request from '@/request'
import { fetch } from '@/utils/http.js'
// import { post } from '@/utils/http.js'
// const part_url = '/comment/';


// 评论审核
/**
 *
* @param {*} id  评论id
* @param {*}auditStatus  审核状态 0--待审核,1--审核通过,2--审核拒绝
 * @returns
 */
function commentAudit(id, auditStatus) {
  return new Promise((resolve, reject) => {
    fetch('/comment/commentAudit', {
      params: {
        id,
        auditStatus,
      }
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err)
    })
  })
}


// 评论审核(批量)
/**
 *
* @param {*} ids  评论id
* @param {*}auditStatus  审核状态 0--待审核,1--审核通过,2--审核拒绝
 * @returns
 */
function commentAuditBatch(ids, auditStatus) {
  return new Promise((resolve, reject) => {
    fetch('/comment/commentAuditBatch', {
      params: {
        ids,
        auditStatus,
      }
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 获取评论列表
 *
 * @param {*} pageNow 当前页
 * @param {*} pageSize 当前页数条数
 * @param {*} guid 名字
 * @param {*} auditStatus 
 * @param {*} source 0 资讯, 1 课程
 * @returns
 */
function getPageList(pageNow, pageSize, guid, auditStatus, source) {
  return new Promise((resolve, reject) => {
    fetch('/comment/getPageList', {
      params: {
        pageNow,
        pageSize,
        guid,
        auditStatus,
        source
      }
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err)
    })
  })
}
/**
 * 单个删除评论
 * @param {*} id 
 * @returns
 */
function commentDelete(id) {
  return new Promise((resolve, reject) => {
    fetch('/comment/delete', {
      params: {
        id
      }
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err)
    })
  })
}
/**
 * 批量删除评论
 * @param {*} ids 
 * @returns
 */
function commentDeleteBatch(ids) {
  return new Promise((resolve, reject) => {
    fetch('/comment/deleteBatch', {
      params: {
        ids
      }
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err)
    })
  })
}


export default {
  commentAudit,
  getPageList,
  commentAuditBatch,
  commentDelete,
  commentDeleteBatch
}
