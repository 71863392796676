import {fetch} from '@/utils/http.js'
import {post} from '@/utils/http.js'
import {postJson} from '@/utils/http.js'


function getById(id, trainClassId) {
    return new Promise((resolve, reject) => {
        fetch('/trainClassNotice/getById', {
            params: {
                id, trainClassId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getPagedList(pageNow, pageSize, trainClassId) {
    return new Promise((resolve, reject) => {
        fetch('/trainClassNotice/getPagedList', {
            params: {
                pageNow, pageSize, trainClassId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function saveOrUpdate(params) {
    return new Promise((resolve, reject) => {
        postJson('/trainClassNotice/saveOrUpdate', {}, params).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function saveFile(params) {
    return new Promise((resolve, reject) => {
        postJson('/trainClassArchive/saveFile', {}, params).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function deleteById(id, trainClassId) {
    return new Promise((resolve, reject) => {
        fetch('/trainClassNotice/deleteById', {
            params: {
                id, trainClassId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    deleteById,
    saveOrUpdate,
    getPagedList,
    getById,
    saveFile
}