/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2020-04-02 16:57:41
 */

import { fetch, postJson } from '@/utils/http.js'





/**
 * 详情
 *
 * @param {*} id
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/courseHour/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 *删除
 *
 * @param {*} id
 * @returns
 */
function deleteById(id) {
    return new Promise((resolve, reject) => {
        fetch('/courseHour/delete', {
            params: { id },
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}



/**
 *上移下移
 *
 * @param {*} id
 * @returns
 */
function sortHour(id, type) {
    return new Promise((resolve, reject) => {
        fetch('/courseHour/sortHour', {
            params: { id, type }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 * 保存
 *
 * @param {*} type
 * @param {*} title
 * @param {*} abstracts
 * @param {*} picUrl
 * @param {*} videoUrl
 * @param {*} content
 * @param {*} pageUrl
 * @param {*} hits
 * @param {*} hourCount
 * @param {*} chapterId
 * @param {*} courseId
 * @returns
 */
function saveOrUpdateHour(courseHour) {
    return new Promise((resolve, reject) => {
        postJson('/courseHour/saveOrUpdateHour', { id: courseHour.id, title: courseHour.title }, courseHour).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


function saveHourSubject(hourSubject) {
    return new Promise((resolve, reject) => {
        postJson('/courseHour/saveHourSubject', {},
            hourSubject
        ).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


function deleteHourSubject(hourId, subjectIds, courseId) {
    return new Promise((resolve, reject) => {
        fetch('/courseHour/deleteHourSubject', {
            params: { hourId, subjectIds, courseId }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function updateHourSubject(hourId, subjectId, score, courseId) {
    return new Promise((resolve, reject) => {
        postJson('/courseHour/updateHourSubject', {}, {
            hourId, subjectId, score, courseId
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function getHourSubject(hourId, courseId) {
    return new Promise((resolve, reject) => {
        fetch('/courseHour/getHourSubject', {
            params: {
                hourId, courseId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function getUploadSign() {
    return new Promise((resolve, reject) => {
        fetch('/courseHour/getUploadSign', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function getHourCountType() {
    return new Promise((resolve, reject) => {
        fetch('/courseHour/getHourCountType', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    getById,
    deleteById,
    saveOrUpdateHour,
    sortHour,
    saveHourSubject,
    deleteHourSubject,
    updateHourSubject,
    getHourSubject,
    getUploadSign,
    getHourCountType
}