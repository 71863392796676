/*
 * @Author: 齐白宇 
 * @Date: 2019-09-17 20:16:10 
 * @Last Modified by: 齐白宇
 * @Last Modified time: 2019-11-05 16:35:36
 */

import { download } from '@/utils/http.js'

function uploadFile() {
    return download("/file/uploadFile");
}

export default {
    uploadFile,
    uploadUpFile: download("/file/uploadUpFile"),
}