/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2022-05-11 12:02:59
 */

import { fetch, post, postJson } from '@/utils/http.js'


/**
 *    分页列表
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @param {*} title  标题
 * @param {*} categoryName 分类名称
 * @param {*} categoryId  分类id
 * @param {*} tag  标签
 * @param {*} auditStatus  审核状态
 * @param {*} recommend  是否推荐
 * @param {*} status  状态
 */
function getPageList(pageNow, pageSize, newsArticle) {
   
    return new Promise((resolve, reject) => {
        fetch('/article/getPageList', {
            params: {
                pageNow,
                pageSize,
                ...newsArticle
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *获取详情
 *
 * @param {*} id
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/article/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *审核
 *
 * @param {*} ids
 * @param {*} auditStatus
 * @param {*} auditRemark
 * @returns
 */
function audit(ids, auditStatus, auditRemark) {
    return new Promise((resolve, reject) => {
        fetch('/article/audit', {
            params: {
                ids,
                auditStatus,
                auditRemark,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *删除
 *
 * @param {*} ids
 * @returns
 */
function removeByIds(ids) {
    return new Promise((resolve, reject) => {
        fetch('/article/removeByIds', {
            params: {
                ids,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *修改状态
 *
 * @param {*} id
 * @param {*} status
 * @returns
 */
function updateStatus(ids, status) {
    return new Promise((resolve, reject) => {
        fetch('/article/updateStatus', {
            params: {
                ids,
                status,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 推荐
 * @param {*} ids 
 * @param {*} recommend 
 */
function recommend(ids, recommend) {
    return new Promise((resolve, reject) => {
        fetch('/article/recommend', {
            params: {
                ids,
                recommend,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 *保存
 *
 * @param {*} title 标题
 * @param {*} tag 标签
 * @param {*} categoryId 分类
 * @param {*} content 内容
 * @param {*} author 作者
 * @param {*} pictures 封面图
 * @param {*} summary 资讯概述
 * @param {*} jumpAddress 链接地址
 * @returns
 */
function saveOrUpdate(newsArticle) {
    return new Promise((resolve, reject) => {
        postJson('/article/saveOrUpdate', { id: newsArticle.id, title: newsArticle.title }, newsArticle).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *
 *置顶
 * @returns
 */
function top(id) {
    return new Promise((resolve, reject) => {
        post('/article/top', {
            id,
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 取消置顶
 * @param id
 */
function cancelTop(id) {
    return new Promise((resolve, reject) => {
        post('/article/cancelTop', {
            id,
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 *
 *上移下移
 */
function upOrDown(id, up) {
    return new Promise((resolve, reject) => {
        post('/article/upOrDown', {
            id, up
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 文章类型
 */
function getArticleTypes() {
    return new Promise((resolve, reject) => {
        fetch('/article/getArticleTypes', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 封面图类型
 */
function getPictureTypes() {
    return new Promise((resolve, reject) => {
        fetch('/article/getPictureTypes', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function pushMessage(id) {
    return new Promise((resolve, reject) => {
        fetch('/article/pushMessage', {
            params: {id}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
export default {
    pushMessage,
    getPageList,
    getById,
    audit,
    removeByIds,
    updateStatus,
    recommend,
    saveOrUpdate,
    top,
    cancelTop,
    upOrDown,
    getArticleTypes,
    getPictureTypes,
}