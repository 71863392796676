<template>
  <a-config-provider :locale="locale">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  name: "app",
  data() {
    return {
      locale: zhCN
    };
  }
};
</script>

<style>
.ql-editor {
  white-space: normal !important;
}

.ql-container {
  white-space: pre-wrap !important;
}
</style>
