import { fetch } from '@/utils/http.js'
import { post } from '@/utils/http.js'

function deleteById(id) {
    return new Promise((resolve, reject) => {
        fetch('/project/deleteById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getPagedList(pageNow, pageSize, name, status) {
    return new Promise((resolve, reject) => {
        fetch('/project/getPagedList', {
            params: {
                pageNow, pageSize, name, status
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function saveOrUpdate(data) {
    return new Promise((resolve, reject) => {
        post('/project/saveOrUpdate', data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/project/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getCertificatesForProject(projectId) {
    return new Promise((resolve, reject) => {
        fetch('/project/getCertificatesForProject', {
            params: {
                projectId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function addCertificateToProject(projectId, certificateIds) {
    return new Promise((resolve, reject) => {
        fetch('/project/addCertificateToProject', {
            params: {
                projectId, certificateIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function removeCertificateFromProject(projectId, certificateIds) {
    return new Promise((resolve, reject) => {
        fetch('/project/removeCertificateFromProject', {
            params: {
                projectId, certificateIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getCourseForProject(projectId) {
    return new Promise((resolve, reject) => {
        fetch('/project/getCourseForProject', {
            params: {
                projectId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function addCourseToProject(projectId, courseIds) {
    return new Promise((resolve, reject) => {
        fetch('/project/addCourseToProject', {
            params: {
                projectId, courseIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function removeCourseFromProject(projectId, courseIds) {
    return new Promise((resolve, reject) => {
        fetch('/project/removeCourseFromProject', {
            params: {
                projectId, courseIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getExaminationForProject(projectId) {
    return new Promise((resolve, reject) => {
        fetch('/project/getExaminationForProject', {
            params: {
                projectId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function addExaminationToProject(projectId, examinationIds) {
    return new Promise((resolve, reject) => {
        fetch('/project/addExaminationToProject', {
            params: {
                projectId, examinationIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function removeExaminationFromProject(projectId, examinationIds) {
    return new Promise((resolve, reject) => {
        fetch('/project/removeExaminationFromProject', {
            params: {
                projectId, examinationIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 获取启用的培训项目列表
 * @param {*} name 
 */
function getProjectList(name) {
    return new Promise((resolve, reject) => {
        fetch('/project/getProjectList', {
            params: {
                name
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function changeStatus(ids, status) {
    return new Promise((resolve, reject) => {
        fetch('/project/changeStatus', {
            params: {
                ids, status
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getTrigger(projectId, id, dataType) {
    return new Promise((resolve, reject) => {
        fetch('/project/getTrigger', {
            params: {
                projectId, id, dataType
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function setTrigger(projectId, id, dataType, type, value, targetIds) {
    return new Promise((resolve, reject) => {
        fetch('/project/setTrigger', {
            params: {
                projectId, id, dataType, type, value, targetIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function updateProjectCourseRequired(projectId, courseId, required) {
    return new Promise((resolve, reject) => {
        fetch('/project/updateProjectCourseRequired', {
            params: {
                projectId, courseId, required
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function getResourceForProject(projectId) {
    return new Promise((resolve, reject) => {
        fetch('/project/getResourceForProject', {
            params: {
                projectId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function addResourceToProject(projectId, resourceIds) {
    return new Promise((resolve, reject) => {
        fetch('/project/addResourceToProject', {
            params: {
                projectId, resourceIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function removeResourceFromProject(projectId, resourceIds) {
    return new Promise((resolve, reject) => {
        fetch('/project/removeResourceIdsFromProject', {
            params: {
                projectId, resourceIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    setTrigger,
    getTrigger,
    changeStatus,
    getById,
    saveOrUpdate,
    getPagedList,
    deleteById,
    getExaminationForProject,
    addExaminationToProject,
    removeExaminationFromProject,
    getCourseForProject,
    addCourseToProject,
    removeCourseFromProject,
    getResourceForProject,
    addResourceToProject,
    removeResourceFromProject,
    getCertificatesForProject,
    addCertificateToProject,
    removeCertificateFromProject,
    getProjectList,
    updateProjectCourseRequired
}