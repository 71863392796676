/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 褚会茹
 * @Last Modified time: 2021-09-06 15:15:14
 */

import { fetch, postJson } from '@/utils/http.js'

/**
 *查询所有课程分类树形
 *
 * @returns
 */
function getAllTypeTree() {
    return new Promise((resolve, reject) => {
        fetch('/courseType/getAllTypeTree', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *查询所有启用状态课程分类树形
 *
 * @returns
 */
function getUseTypeTree() {
    return new Promise((resolve, reject) => {
        fetch('/courseType/getUseTypeTree', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *添加
 *
 * @param {*} name  名称
 * @param {*} parentId 父类id
 * @param {*} icon 图标
 * @returns
 */
function saveOrUpdate(courseType) {
    return new Promise((resolve, reject) => {
        postJson('/courseType/saveOrUpdate', { id: courseType.id, name: courseType.name }, courseType).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *删除
 *
 * @param {*} id
 * @returns
 */
function removeById(id) {
    return new Promise((resolve, reject) => {
        fetch('/courseType/delete', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *获取详情
 *
 * @param {*} id
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/courseType/getById', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *课程分类回显
 *
 * @param {*} id
 * @returns
 */
function getParentIdList(id) {
    return new Promise((resolve, reject) => {
        fetch('/courseType/getParentIdList', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改状态
 *
 * @param {*} id 编号
 * @param {*} status 有效状态，1有效0无效
 * @returns
 */
function updateStatus(id, status) {
    return new Promise((resolve, reject) => {
        fetch('/courseType/updateStatus', {
            params: {
                id,
                status,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改排序
 * @param {*} id 分类id
 * @param {*} type  类型 0 下移 1 上移
 */
function sortCourseType(id, type) {
    return new Promise((resolve, reject) => {
        fetch('/courseType/sortCourseType', {
            params: {
                id,
                type,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export default {
    getAllTypeTree,
    getUseTypeTree,
    saveOrUpdate,
    removeById,
    getById,
    getParentIdList,
    updateStatus,
    sortCourseType
}