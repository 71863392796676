import { fetch, post, postJson, download } from '@/utils/http.js'

function getPagedList(pageNow, pageSize, name) {
    return new Promise((resolve, reject) => {
        fetch('/enterprise/getPagedList', {
            params: {
                pageNow, pageSize, name
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })

}
function saveOrUpdate(data) {
    return new Promise((resolve, reject) => {
        postJson('/enterprise/saveOrUpdate', { id: data.id, name: data.name }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/enterprise/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


function updateStatus(ids, status) {
    return new Promise((resolve, reject) => {
        post('/enterprise/updateStatus', { ids, status }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getAll() {
    return new Promise((resolve, reject) => {
        fetch('/enterprise/getAll', {
            params: {

            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })

}

function importData() {
    return download("/enterprise/importData", {
    });
}

function exportData(name) {
    return download("/enterprise/exportData", {
        name
    });
}

function downloadTemplate() {
    return download("/enterprise/downloadTemplate", {
    });
}

export default {
    getPagedList,
    saveOrUpdate,
    getById,
    updateStatus,
    getAll,
    exportData,
    importData,
    downloadTemplate,
}