/*
 * @Author: 齐白宇 
 * @Date: 2019-12-14 09:22:20 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2020-04-03 12:02:05
 */

import { fetch } from '@/utils/http.js'
import { post } from '@/utils/http.js'

/**
 * 分页列表
 *
 * @param {*} pageNow 当前页数
 * @param {*} pageSize 每页条数
 * @returns
 */
function getPageList(pageNow, pageSize) {
    return new Promise((resolve, reject) => {
        fetch('/message/getPageList', {
            params: {
                pageNow,
                pageSize,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 发送消息
 *
 * @param {*} content 消息通知内容
 * @param {*} type 类型:0发送给选中用户 1发送给所有用户
 * @param {*} userIds 用户ID,逗号分隔 type为0的时候必填
 * @returns
 */
function save(content, type, userIds, title) {
    return new Promise((resolve, reject) => {
        post('/message/save', {
            content,
            type,
            userIds,
            title
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function deleteByIds(ids) {
    return new Promise((resolve, reject) => {
        fetch('/message/delete', {
            params: {
                ids
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    getPageList,
    save,
    deleteByIds
}