/*
 * @Author: 齐白宇 
 * @Date: 2019-09-20 09:02:01 
 * @Last Modified by: 齐白宇
 * @Last Modified time: 2019-09-20 09:42:54
 */

import {
    fetch,
} from '@/utils/http.js'

/**
 * 分页列表
 *
 * @param {*} pageNow 当先页数;默认为1
 * @param {*} pageSize 每页条数;默认为10
 * @returns
 */
function pageList(pageNow, pageSize, orderStatus, name, productName, startTime, endTime) {
    return new Promise((resolve, reject) => {
        fetch('/order/getOrderPageList', {
            params: {
                pageNow,
                pageSize,
                orderStatus,
                name,
                productName,
                startTime,
                endTime
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}



export default {
    pageList,
}