import Vue from 'vue'
import Vuex from 'vuex'
import breadcrumb from "./modules/breadcrumb"

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        breadcrumb,
    },
    strict: "debug",
});

export default store;