/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 褚会茹
 * @Last Modified time: 2021-09-06 17:04:27
 */

import { fetch, postJson, download } from '@/utils/http.js'


/**
 *
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @param {*} question
 * @returns
 */
function getPageList(pageNow, pageSize, question, category) {
    return new Promise((resolve, reject) => {
        fetch('/subject/getPageList', {
            params: {
                pageNow,
                pageSize,
                question,
                category,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 查询可用题目列表
 * @param {*} pageNow 
 * @param {*} pageSize 
 * @param {*} question 
 * @param {*} category 
 * @returns 
 */
function getPageListForUse(pageNow, pageSize, question, category) {
    return new Promise((resolve, reject) => {
        fetch('/subject/getPageListForUse', {
            params: {
                pageNow,
                pageSize,
                question,
                category,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存
 *
 * @param {*} subjectType
 * @param {*} categoryId
 * @param {*} difficult
 * @param {*} useSeconds
 * @param {*} questContent
 * @param {*} score
 * @param {*} imgUrl
 * @param {*} optionConf
 * @param {*} answerExplain
 * @param {*} answer
 * @param {*} nullify
 * @param {*} random
 * @returns
 */
function saveOrUpdate(subject) {
    return new Promise((resolve, reject) => {
        postJson('/subject/saveOrUpdate', { id: subject.id, questContent: subject.questContent }, subject).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 详情
 *
 * @param {*} id
 * @returns
 */
function getDetail(id) {
    return new Promise((resolve, reject) => {
        fetch('/subject/getDetail', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 * 删除
 *
 * @param {*} id
 * @returns
 */
function removeById(id) {
    return new Promise((resolve, reject) => {
        fetch('/subject/delete', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 作废
 *
 * @param {*} id
 * @returns
 */
function nullify(id) {
    return new Promise((resolve, reject) => {
        fetch('/subject/nullify', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 启用
 *
 * @param {*} id
 * @returns
 */
function valid(id, remoteType) {
    return new Promise((resolve, reject) => {
        fetch('/subject/valid', {
            params: {
                id,
                remoteType
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function getTypeCount(category) {
    return new Promise((resolve, reject) => {
        fetch('/subject/getTypeCount', {
            params: {
                category
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getRandomList(category, singleCount, multiCount, judgeCount) {
    return new Promise((resolve, reject) => {
        fetch('/subject/getRandomList', {
            params: {
                category,
                singleCount,
                multiCount,
                judgeCount
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getCountGroupByTypeDifficult(category) {
    return new Promise((resolve, reject) => {
        fetch('/subject/getCountGroupByTypeDifficult', {
            params: {
                category
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function importSubject() {
    return download("/subject/importSubject", {
    });
}

function downloadTemp() {
    return download("/subject/exportTemplate", {
    });
}


export default {
    getPageList,
    getPageListForUse,
    getDetail,
    saveOrUpdate,
    removeById,
    nullify,
    getTypeCount,
    getRandomList,
    getCountGroupByTypeDifficult,
    downloadTemp,
    importSubject,
    valid,
}