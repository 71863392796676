/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 褚会茹
 * @Last Modified time: 2019-11-02 17:34:45
 */

import { fetch, postJson } from '@/utils/http.js'



/**
 * 友链列表
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @param {*} name
 * @param {*} status
 * @returns
 */
function getPageList(pageNow, pageSize, name, status) {
    return new Promise((resolve, reject) => {
        fetch('/friendLink/getPageList', {
            params: {
                pageNow,
                pageSize,
                name,
                status
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *获取详情
 *
 * @param {*} id
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/friendLink/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}



/**
 *删除
 *
 * @param {*} id
 * @returns
 */
function deleteFriendlink(ids) {
    return new Promise((resolve, reject) => {
        fetch('/friendLink/deleteBatch', {
            params: {
                ids,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存
 *
 * @param {*} name
 * @param {*} link
 * @param {*} type
 * @param {*} logo
 * @returns
 */
function saveOrUpdate(friendLink) {
    return new Promise((resolve, reject) => {
        postJson('/friendLink/saveOrUpdate', { id: friendLink.id, name: friendLink.name }, friendLink).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改状态
 * @param {*} ids 
 * @param {*} status 
 */
function updateStatus(ids, status) {
    return new Promise((resolve, reject) => {
        fetch('/friendLink/updateStatus', {
            params: {
                ids,
                status
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 * 修改排序
 * @param {*} id 
 * @param {*} type 
 */
function upOrDown(id, type) {
    return new Promise((resolve, reject) => {
        fetch('/friendLink/sortFriendLink', {
            params: {
                id,
                type
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    getPageList,
    getById,
    deleteFriendlink,
    saveOrUpdate,
    updateStatus,
    upOrDown
}