/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2020-04-03 13:49:13
 */

import {
    fetch
} from '@/utils/http.js'


/**
 * 就业列表
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @returns
 */
function getPracticePagedList(pageNow, pageSize, name) {
    return new Promise((resolve, reject) => {
        fetch('/recruit/getPracticePagedList', {
            params: {
                pageNow,
                pageSize,
                name
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *就业详情
 *
 * @param {*} id
 * @returns
 */
function getRecruitPractice(id) {
    return new Promise((resolve, reject) => {
        fetch('/recruit/getRecruitPractice', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 * 删除就业记录
 * @param {} ids 
 * @returns 
 */
function deleteRecruitPractice(ids) {
    return new Promise((resolve, reject) => {
        fetch('/recruit/deleteRecruitPractice', {
            params: {
                ids,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}



/**
 * 查询招聘需求
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @returns
 */
function getRequirePagedList(pageNow, pageSize, staiton, education) {
    return new Promise((resolve, reject) => {
        fetch('/recruit/getRequirePagedList', {
            params: {
                pageNow,
                pageSize,
                staiton,
                education
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *获取招聘详情
 *
 * @param {*} id
 * @returns
 */
function getRecruitRequire(id) {
    return new Promise((resolve, reject) => {
        fetch('/recruit/getRecruitRequire', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 * 删除招聘需求
 * @param {*} ids 
 * @returns 
 */
function deleteRecruitRequire(ids) {
    return new Promise((resolve, reject) => {
        fetch('/recruit/deleteRecruitRequire', {
            params: {
                ids,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function createProve(id, certificateId) {
    return new Promise((resolve, reject) => {
        fetch('/recruit/createProve', {
            params: {
                id,
                certificateId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}



export default {
    getPracticePagedList,
    getRecruitPractice,
    deleteRecruitPractice,
    getRequirePagedList,
    getRecruitRequire,
    deleteRecruitRequire,
    createProve
}