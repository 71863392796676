<template>
    <router-view></router-view>
</template>
<script>
export default {
  name: "rightMain",
  data() {
    return {
      collapsed: false,
    };
  },
  mounted: function () {
    this.$nextTick(function () {});
  },
};
</script>

<style>
</style>

