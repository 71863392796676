/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2020-04-02 16:57:41
 */

import { fetch, postJson } from '@/utils/http.js'

/**
 * 详情
 *
 * @param {*} id
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/resourceChapterData/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *删除
 *
 * @param {*} id
 * @returns
 */
function deleteById(id) {
    return new Promise((resolve, reject) => {
        fetch('/resourceChapterData/deleteById', {
            params: { id }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 保存
 *
 * @param {*} title
 * @param {*} content
 * @param {*} chapterId
 * @param {*} resourceId
 * @param {*} resourceType
 * @param {*} duration
 * @param {*} fileLength
 * @param {*} fileName
 * 
 * @returns
 */
function saveOrUpdate(data) {
    return new Promise((resolve, reject) => {
        postJson('/resourceChapterData/saveOrUpdate', {
            id: data.id, title: data.title,
        }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getFileType() {
    return new Promise((resolve, reject) => {
        fetch('/resourceChapterData/getFileType', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    getById,
    deleteById,
    saveOrUpdate,
    getFileType,
}