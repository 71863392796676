<template>
  <a-menu
    theme="dark"
    mode="inline"
    v-model="active"
    @openChange="onOpenChange"
    :openKeys="parentAcive"
  >
    <template v-for="item in leftMenus">
      <a-menu-item
        :key="item.name"
        @click="goTo(item.path)"
        v-if="!item.children && item.meta.leftMenu"
      >
        <a-icon :type="item.meta.icon" />
        <span>{{ item.meta.name }}</span>
      </a-menu-item>

      <a-sub-menu :key="item.name" v-if="item.children && item.meta.leftMenu">
        <span slot="title">
          <a-icon :type="item.meta.icon" />
          <span>{{ item.meta.name }}</span>
        </span>
        <template v-for="info in item.children">
          <a-menu-item :key="info.name" v-if="info.meta.leftMenu">
            <router-link :to="info.path">{{ info.meta.name }}</router-link>
          </a-menu-item>
        </template>
      </a-sub-menu>
    </template>
  </a-menu>
</template>
<script>
export default {
  name: "goTo",
  data() {
    return {
      collapsed: false,
      leftMenus: [],
      active: [], //选中的子节点
      parentAcive: [], //选中的父节点
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      this.active.push(this.$route.name);
      var parentUrl = this.$route.path.split("/");
      this.parentAcive.push(parentUrl[parentUrl.length - 2]); //根据路径取出父节点
      this.leftMenus = this.$commen.getThisBreadcrumbs(
        this.$leftMenus,
        this.$route
      )[1].children;
    });
  },
  methods: {
    onOpenChange(res) {
      //左侧菜单展示隐藏
      this.parentAcive = res.length == 0 ? [] : [res[res.length - 1]];
    },
    isAuthority(s) {
      //左侧菜单项判断是否有权限
      var isPass = false;
      if (s) {
        var roles = localStorage.getItem(this.$msg.user.authority).split(","); //获取本地个人权限
        for (var j = 0; j < s.length; j++) {
          var info = s[j];
          if (roles.indexOf(info) > -1) {
            isPass = true;
            break; //只要有一个权限满足，此菜单项就显示
          }
        }
      } else {
        //未配置权限，默认为允许访问
        isPass = true;
      }
      return isPass;
    },
    isShowMenu(s) {
      var isPass = true;
      if (s) {
        isPass =
          s.indexOf(this.$cookies.get(this.$msg.user.level)) > -1
            ? true
            : false;
      }
      return isPass;
    },
    goTo(s) {
      this.$router.push({ path: s });
    },
    LeftMenuChange(e, info) {
      if (e.children != undefined) {
        this.leftMenus = e.children;
        this.active = [];
        this.active.push(info.name);
        var parentUrl = info.path.split("/");
        this.parentAcive.push(parentUrl[parentUrl.length - 2]); //根据路径取出父节点
      } else {
        this.leftMenus = [];
        this.active = [];
      }
    },
  },
};
</script>

<style>
</style>

