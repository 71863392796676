/*
 * @Author: 齐白宇 
 * @Date: 2019-04-01 16:35:16 
 * @Last Modified by: 贾晓红
 * @Last Modified time: 2019-04-29 16:37:16
 */
export default {
    user: {
        Authentication: "Authenticationdls", //个人凭证
        authority: "authority", //个人权限
        username: "username", //登录用户名
        level: "level", //代理商等级
        apiUrl: "http://localhost:8083",
    },
    responseCode: { //接口返回状态
        success: "0000", //通过
        fail: "0001", //失败
        noAuthority: "0002", //没权限
        exception: "0003", //异常
        needLogin: "0004", //凭证失效
    },
    sys: {
        size: 'small', //控件大小 small、large、default
    }
}