<template>
    <div class="notFound"><img src="../../assets/img/404.jpg" /></div>
</template>
<script>
export default {
  name: "notFound",
  data() {
    return {
      collapsed: false
    };
  },
  mounted: function() {
    this.$nextTick(function() {
    
    });
  }
};
</script>

<style>

.notFound{
    text-align: center;
    background-color: #fff;
}

.notFound  img{
    width: 40%;
}
</style>

