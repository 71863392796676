/*
 * @Author: 齐白宇 
 * @Date: 2019-09-17 17:40:55 
 * @Last Modified by: 齐白宇
 * @Last Modified time: 2019-09-17 18:06:24
 */

import { fetch, postJson } from '@/utils/http.js'


/**
 * 获取app版本
 *
 * @returns
 */
function getAppVersion() {
    return new Promise((resolve, reject) => {
        fetch('/setting/getAppVersion', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 设置app版本
 *
 * @param {*} andriodVersion 安卓版本号
 * @param {*} andriodUrl 安卓地址
 * @param {*} andriodVersionCode 安卓版本码
 * @param {*} iosVersion ios版本号
 * @param {*} iosUrl ios地址
 * @param {*} iosVersionCode ios版本码
 * @returns
 */
function setAppVersion(andriodVersion, andriodUrl, andriodVersionCode, iosVersion, iosUrl, iosVersionCode) {
    return new Promise((resolve, reject) => {
        postJson('/setting/setAppVersion', {}, {
            andriodVersion,
            andriodUrl,
            andriodVersionCode,
            iosVersion,
            iosUrl,
            iosVersionCode,
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


export default {
    getAppVersion,
    setAppVersion,
}