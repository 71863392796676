<template>
  <a-layout id="components-layout-demo-fixed-sider">
    <a-layout-sider
      :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }"
      v-model="collapsed"
    >
      <div class="logo">
        <h1 style="font-size: 16px; margin-right: 10px" v-if="!collapsed">
          <img
            style="margin-right: 10px; width: 22px"
            src="@/assets/img/logo.png"
          />红电职业培训
        </h1>
      </div>
      <leftMenu ref="thisLeftMenu" />
      <div v-if="!hasLeftMenu" class="custom_left">
        <div class="top">
          <img src="@/assets/img/touxiang.jpg" />
          <div style="margin-top: 16px">
            {{ $cookies.get($msg.user.username) }}
          </div>
          <a-button
            style="margin-top: 16px; width: 140px; height: 35px"
            @click="gotoSystem('/user/rolelist')"
            >系统设置</a-button
          >
        </div>
        <div class="bottom">
          <img class="tel" src="@/assets/img/tel.png" />
          <div style="margin-top: 16px">技术服务热线</div>
          <div>{{ telephone }}</div>
          <img style="width: 70px; margin-top: 22px" :src="codeUrl" />
          <div style="margin-top: 16px">红电职业技能培训系统</div>
        </div>
      </div>
    </a-layout-sider>
    <a-layout :style="{ marginLeft: collapsed ? '81px' : '200px' }">
      <a-layout-header class :style="{ background: '#fff', padding: 0 }">
        <a-icon
          class="pxkeji-admin-header-trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <!-- 主页导航菜单 -->
        <div class="pxkeji-admin-header-meau">
          <a-menu
            v-model="current"
            mode="horizontal"
            class="pxkeji-horizontal-menu"
          >
            <template v-for="item in headMenuData">
              <a-menu-item
                :key="item.name"
                v-if="item.meta.leftMenu"
                @click="goTo({ item }, $event)"
              >
                <i class="iconfont" :class="item.meta.icon"></i>
                {{ item.meta.name }}
              </a-menu-item>
            </template>
          </a-menu>
        </div>
        <div class="pxkeji-admin-header-right">
          <a-dropdown>
            <a class="ant-dropdown-link pxkeji-header-user-info" href="#">
              <a-badge>
                <a-avatar
                  shape="square"
                  size="default"
                  icon="user"
                  :style="{
                    verticalAlign: 'middle',
                    backgroundColor: '#1890ff',
                  }"
                ></a-avatar>
              </a-badge>
              <span class="pxkeji-header-user-name">{{ username }}</span>
            </a>
            <a-menu slot="overlay" class="pxkeji-header-select">
              <a-menu-item @click="modifyPass">
                <a-icon type="lock" />设置密码
              </a-menu-item>
              <a-menu-item @click="logOut">
                <a-icon type="logout" />退出
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content>
        <pxkeji-rightHeader />
        <div class="mainContent">
          <rightMain />
          <a-back-top />
        </div>
      </a-layout-content>
    </a-layout>
    <a-modal
      v-model="visible"
      :maskClosable="false"
      destroyOnClose
      title="修改密码"
      on-ok="handleOk"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk"
        >
          确认
        </a-button>
      </template>
      <a-form-model
        ref="resetPwdForm"
        :model="form"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item label="原密码" prop="passwordOld">
          <a-input
            v-model="form.passwordOld"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="passwordNew">
          <a-input
            v-model="form.passwordNew"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="checkPass">
          <a-input
            v-model="form.checkPass"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-layout>
</template>

<script>
import leftMenu from "@/components/basic/leftMenu.vue";
import rightMain from "@/components/basic/rightMain.vue";
export default {
  name: "admin",
  components: {
    leftMenu,
    rightMain,
  },
  data() {
    let checkPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.form.passwordNew) {
        callback(new Error("两次新密码输入不相同"));
      } else {
        callback();
      }
    };
    return {
      collapsed: false,
      username: "",
      current: [],
      headMenuData: [],
      hasLeftMenu: false,
      codeUrl: "",
      telephone: "",
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      },
      form: {},
      rules: {
        passwordOld: [
          {
            required: true,
            message: "请输入原密码",
            trigger: "blur",
          },
        ],
        passwordNew: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
        checkPass: [
          {
            required: true,
            message: "请再次输入新密码",
            trigger: "blur",
          },
          { validator: checkPass, trigger: "change" },
        ],
      },
      visible: false,
      loading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.username = this.$cookies.get(this.$msg.user.username); //登录用户名
      this.headMenuData = this.$leftMenus;
      this.hasLeftMenu =
        this.$refs.thisLeftMenu.leftMenus &&
        this.$refs.thisLeftMenu.leftMenus.length > 0;
      this.getInfo();
    });
  },
  methods: {
    logOut() {
      this.$cookies.remove(this.$msg.user.Authentication); //移除登录凭证
      this.$cookies.remove(this.$msg.user.authority); //移除用户权限
      this.$router.push({ path: "/login" });
    },
    gotoSystem(s) {
      this.$router.push(s);
    },
    getInfo() {
      //获取列表
      this.$api.systemSetting
        .getPortalSetting()
        .then((res) => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            if (res.data) {
              this.codeUrl = res.data.url;
              this.telephone = res.data.telephone1;
            }
          } else {
            this.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    goTo(s) {
      var thisInfo = {
        isPass: false,
        data: s.item,
      };
      while (thisInfo.isPass == false) {
        thisInfo = this.getlastInfo(thisInfo.data);
      }
      if (s.item.children) {
        this.$router.push({ path: thisInfo.data.path });
      } else {
        this.$router.push({
          path: s.item.path,
        });
      }
      this.$refs.thisLeftMenu.LeftMenuChange(s.item, thisInfo.data);
      this.hasLeftMenu =
        this.$refs.thisLeftMenu.leftMenus &&
        this.$refs.thisLeftMenu.leftMenus.length > 0;
    },
    getlastInfo(e) {
      if (e.children) {
        return { isPass: false, data: e.children[0] };
      } else {
        return { isPass: true, data: e };
      }
    },
    modifyPass() {
      let _this = this;
      _this.visible = true;
    },
    handleOk() {
      let _this = this;
      _this.$refs.resetPwdForm.validate((valid) => {
        if (valid) {
          _this.loading = true;
          _this.$api.user
            .updatePassword(_this.form.passwordOld, _this.form.passwordNew)
            .then((res) => {
              console.log("res:",res)
              console.log("_this.$commons.RespCode.success:",_this.$commons.RespCode.success)
              if (res.errorCode === _this.$commons.RespCode.success) {
                _this.$message.success("修改成功");
                _this.visible = false;
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      let _this = this;
      _this.visible = false;
    },
  },
};
</script>

<style>
.ant-layout-header {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.pxkeji-admin-header-trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  float: left;
}

.pxkeji-admin-header-right {
  float: right;
  padding-right: 20px;
}

.pxkeji-admin-header-right .ant-avatar {
  margin-left: 26px;
}

.pxkeji-admin-header-right .anticon {
  font-size: 20px;
  vertical-align: middle;
}

.pxkeji-header-select a i {
  margin-right: 8px;
}

.pxkeji-header-user-info {
  margin-right: 26px;
}

.pxkeji-header-user-name {
  margin-left: 16px;
  color: #595959;
}

.pxkeji-admin-header-trigger:hover {
  color: #1890ff;
}

.logo img {
  width: 34px;
  display: inline-block;
  vertical-align: top;
  margin-left: 7px;
}

.logo h1 {
  display: inline-block;
  /*margin: 0 0 0 12px;*/
  color: #fff;
  font-weight: 600;
  /*font-size: 20px;*/
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
}

#components-layout-demo-fixed-sider .logo {
  /* height: 32px;
  background: rgba(255, 255, 255, 0.2); */
  margin: 16px;
}
.pxkeji-admin-main {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pxkeji-admin-header-meau {
  float: left;
  width: 75%;
}
.pxkeji-admin-header-meau .ant-menu-item {
  font-size: 16px;
  font-weight: 600;
}
.pxkeji-admin-header-meau .ant-menu-item .iconfont {
  font-size: 18px;
  font-weight: 500;
}
.pxkeji-horizontal-menu {
  line-height: 62px;
}
.pxkeji-horizontal-menu.ant-menu-horizontal {
  border: 0;
}

.pxkeji-horizontal-menu {
  width: 100%;
}
.mainContent {
  background: #ffffff;
  margin: 10px;
  min-height: calc(100vh - 116px);
}
.custom_left {
  width: 100%;
  height: 88vh;
  position: relative;
}
.custom_left .top {
  width: 100%;
  position: absolute;
  text-align: center;
  top: 72px;
}
.custom_left div {
  color: #fff;
}
.custom_left .bottom {
  width: 100%;
  position: absolute;
  bottom: 40px;
  text-align: center;
}
.top img {
  width: 73px;
  height: 73px;
  border-radius: 100px;
}
.tel {
  width: 38px;
  height: 31px;
}
/* .top_statistic {
  height: 170px;
} */
</style>
