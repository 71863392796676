<template>
  <div
    :style="{ padding: '5px 10px', background: '#fff', marginTop: '1px' }"
    v-if="thisBreadcrumbs.length > 2"
  >
    <a-breadcrumb class="pxkeji-breadcrumb">
      <a-breadcrumb-item :key="index" v-for="(item, index) in thisBreadcrumbs">
        <template> {{ item.meta.name }}</template>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "rightHeader",
  data() {
    return {
      title: "", //当前导航
      thisBreadcrumbs: [], //面包屑导航
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      this.thisBreadcrumbs = this.$commen.getThisBreadcrumbs(
        this.$leftMenus,
        this.$route
      );
      this.title = this.$route.meta.name;
    });
  },
  computed: {
    ...mapGetters({
      breadcrumbs: "breadcrumb/getBreadcrumb",
    }),
  },
  watch: {
    breadcrumbs(val) {
      this.thisBreadcrumbs = val;
    },
  },
};
</script>

<style>
</style>

