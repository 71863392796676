/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2020-04-03 13:49:13
 */

import { fetch, postJson } from '@/utils/http.js'


/**
 *
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @param {*} type
 * @param {*} courseName
 * @param {*} courseFrom
 * @param {*} classType
 * @param {*} status
 * @returns
 */
function getPageList(pageNow, pageSize, typeId, name, status) {
    return new Promise((resolve, reject) => {
        fetch('/resource/getPageList', {
            params: {
                pageNow,
                pageSize,
                typeId,
                name,
                status,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *获取详情
 *
 * @param {*} id
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/resource/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *修改资料状态
 *
 * @param {*} id
 * @param {*} status
 * @returns
 */
function updateStatusByIds(ids, status) {
    return new Promise((resolve, reject) => {
        fetch('/resource/updateStatusByIds', {
            params: {
                ids,
                status,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function deleteById(id) {
    return new Promise((resolve, reject) => {
        fetch('/resource/deleteResource', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function updateRecommendById(resourceId, recommend) {
    return new Promise((resolve, reject) => {
        fetch('/resource/updateRecommendById', {
            params: {
                resourceId,
                recommend,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}



/**
 * 保存
 *
 * @param {*} name
 * @param {*} type
 * @param {*} introduce
 * @param {*} picUrl
 * @returns
 */
function saveOrUpdate(data) {
    return new Promise((resolve, reject) => {
        postJson('/resource/saveOrUpdate', {
            id: data.id, name: data.name,
        }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 * 新增章节
 *
 * @param {*} id
 * @param {*} title
 * @param {*} tag
 * @param {*} categoryId
 * @param {*} content
 * @param {*} author
 * @returns
 */
function saveOrUpdateChapter(data) {
    return new Promise((resolve, reject) => {
        postJson('/resource/saveOrUpdateChapter', {
            id: data.id, title: data.title,
        }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 删除章节
 *
 * @param {*} chapterId
 * @returns
 */
function deleteChapter(chapterId) {
    return new Promise((resolve, reject) => {
        console.log("chapterId", chapterId);
        fetch('/resource/deleteChapter', {
            params: { chapterId }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 章节详情
 *
 * @param {*} chapterId
 * @returns
 */
function getByChapterId(chapterId) {
    return new Promise((resolve, reject) => {
        fetch('/resource/getByChapterId', {
            params: {
                chapterId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


function sortData(id, isUp, type) {
    return new Promise((resolve, reject) => {
        fetch('/resource/sortData', {
            params: {
                id, isUp, type
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function getResourceDetail(resourceId) {
    return new Promise((resolve, reject) => {
        fetch('/resource/getResourceDetail', {
            params: {
                resourceId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getResourceGroupList() {
    return new Promise((resolve, reject) => {
        fetch('/resource/getResourceGroupList', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    getPageList,
    getById,
    updateStatusByIds,
    deleteById,
    saveOrUpdate,
    updateRecommendById,
    saveOrUpdateChapter,
    deleteChapter,
    getByChapterId,
    sortData,
    getResourceDetail,
    getResourceGroupList
}