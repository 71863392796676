/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2020-04-26 18:14:43
 */

import { fetch, postJson, post } from '@/utils/http.js'



/**
 * 证书列表
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @param {*} creType
 * @param {*} years
 * @param {*} name
 * @param {*} status
 * @returns
 */
function getPageList(pageNow, pageSize, type, name, status) {
    return new Promise((resolve, reject) => {
        fetch('/certificate/getPageList', {
            params: {
                pageNow,
                pageSize,
                type,
                name,
                status
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *获取证书详情
 *
 * @param {*} id
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/certificate/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
/**
 *获取所有证书
 *
 * @param {*} id
 * @returns
 */
function getAllForUse() {
    return new Promise((resolve, reject) => {
        fetch('/certificate/getAllForUse', {
            params: {

            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 证书保存
 *
 * @param {*} name
 * @param {*} type
 * @param {*} status
 * @param {*} imageUrl
 * @param {*} content
 * @returns
 */
function saveOrUpdate(data) {
    return new Promise((resolve, reject) => {
        postJson('/certificate/saveOrUpdate', { id: data.id, name: data.name }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 *  证书列表禁用启用
 *
 * @param {*} status
 * @returns
 */
function updateStatus(ids, status) {
    return new Promise((resolve, reject) => {
        post('/certificate/updateStatus', {
            ids,
            status
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *  删除
 *
 * @param {*} status
 * @returns
 */
function deleteById(id) {
    return new Promise((resolve, reject) => {
        post('/certificate/deleteById', {
            id
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getRecordList(pageNow, pageSize, certificateName, studentName) {
    return new Promise((resolve, reject) => {
        fetch('/certificate/getRecordList', {
            params: {
                pageNow,
                pageSize,
                certificateName,
                studentName,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getCertificateRecordDetail(recordId) {
    return new Promise((resolve, reject) => {
        fetch('/certificate/getCertificateRecordDetail', {
            params: {
                recordId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function deleteRecordById(id) {
    return new Promise((resolve, reject) => {
        fetch('/certificate/deleteRecordById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function auditApplyRecord(id, auditStatus, auditOpinion) {
    return new Promise((resolve, reject) => {
        post('/certificate/auditApplyRecord', {
            id, auditStatus, auditOpinion
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getContentTypeList() {
    return new Promise((resolve, reject) => {
        fetch('/certificate/getContentTypeList', {
            params: {}
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function createCertificate(recordId, middleId) {
    return new Promise((resolve, reject) => {
        fetch('/certificate/createCertificate', {
            params: { recordId, middleId }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function getCertificatesByType(type) {
    return new Promise((resolve, reject) => {
        fetch('/certificate/getCertificatesByType', {
            params: { type }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    getPageList,
    getById,
    saveOrUpdate,
    updateStatus,
    deleteById,
    getRecordList,
    getCertificateRecordDetail,
    auditApplyRecord,
    getAllForUse,
    getContentTypeList,
    createCertificate,
    getCertificatesByType,
    deleteRecordById
}