import { fetch, postJson } from '@/utils/http.js'

function deleteById(id) {
    return new Promise((resolve, reject) => {
        fetch('/teacher/deleteById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/teacher/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getPagedList(pageNow, pageSize, name, company) {
    return new Promise((resolve, reject) => {
        fetch('/teacher/getPagedList', {
            params: {
                pageNow, pageSize, name, company,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })

}

function saveOrUpdate(data) {
    return new Promise((resolve, reject) => {
        postJson('/teacher/saveOrUpdate', { id: data.id, fullName: data.fullName }, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getTeacherList(name) {
    return new Promise((resolve, reject) => {
        fetch("/teacher/getTeacherList", {
            params: { name }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export default {
    saveOrUpdate,
    deleteById,
    getPagedList,
    getById,
    getTeacherList
}