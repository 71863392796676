import { fetch, post, postJson } from '@/utils/http.js'

/**
 * 修改状态
 *
 * @param {*} id 编号
 * @param {*} status 有效状态，1有效0无效
 * @returns
 */
function updateStatus(id, status) {
    return new Promise((resolve, reject) => {
        fetch('/newsCategory/updateStatus', {
            params: {
                id,
                status,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 根据id查询
 *
 * @param {*} id 编号
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/newsCategory/getById', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改
 *
 * @param {*} id
 * @param {*} parentId 父栏目id
 * @param {*} name 名字
 * @param {*} category 类型 
 * @param {*} linkUrl 跳转地址
 * @param {*} picture 展示小图标
 * @param {*} advImg 广告图
 * @param {*} appShow 是否在app显示
 * @param {*} pcShow 是否在pc显示
 * @param {*} isBottom 是否底部导航
 * @param {*} status 有效状态，1有效0无效
 * @param {*} websiteTitle 网站标题
 * @param {*} websiteKeyword 网站关键字
 * @param {*} websiteDesc 网站描述
 */
function saveOrUpdate(newsCategory) {
    return new Promise((resolve, reject) => {
        postJson('/newsCategory/saveOrUpdate', { id: newsCategory.id, title: newsCategory.title }, newsCategory).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 删除
 *
 * @param {*} id 编号
 * @returns
 */
function del(id) {
    return new Promise((resolve, reject) => {
        fetch('/newsCategory/removeById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 新闻资讯分类树形结构数据
 * @param {*} status 0 禁用 1 启用 不传为全部
 * @param {*} containLink 是否包含链接类型分类
 */
function getTree(status, containLink) {
    return new Promise((resolve, reject) => {
        fetch('/newsCategory/getTree', {
            params: {
                status,
                containLink
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改排序
 * @param {*} id 分类id
 * @param {*} type  类型 0 下移 1 上移
 */
function sortCategory(id, type) {
    return new Promise((resolve, reject) => {
        post('/newsCategory/sortCategory', { id, type }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}
/**
 * 查询类型
 */
function getCategoryTypes() {
    return new Promise((resolve, reject) => {
        fetch('/newsCategory/getCategoryTypes', {
            params: {
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 获取父级id
 */
function getParents(id) {
    return new Promise((resolve, reject) => {
        fetch('/newsCategory/getParents', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    updateStatus,
    getById,
    saveOrUpdate,
    del,
    getTree,
    sortCategory,
    getCategoryTypes,
    getParents
}