import { fetch } from '@/utils/http.js'

function deleteById(ids, trainClassId) {
    return new Promise((resolve, reject) => {
        fetch('/trainEnroll/deleteById', {
            params: {
                ids, trainClassId
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/trainEnroll/getById', {
            params: {
                id
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


function changeStatus(params) {
    return new Promise((resolve, reject) => {
        fetch('/trainEnroll/changeStatus', {
            params: params
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}
function addStudentEnrollBatch(trainClassId, studentIds) {
    return new Promise((resolve, reject) => {
        fetch('/trainEnroll/addStudentEnrollBatch', {
            params: {
                trainClassId, studentIds
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

function getPagedList(pageNow, pageSize, trainClassId, name) {
    return new Promise((resolve, reject) => {
        fetch('/trainEnroll/getPagedList', {
            params: {
                pageNow, pageSize, trainClassId, name
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })

}


export default {
    deleteById,
    getPagedList,
    changeStatus,
    getById,
    addStudentEnrollBatch
}