/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 贾晓红
 * @Last Modified time: 2019-12-30 09:37:01
 */

import { fetch, post, postJson } from '@/utils/http.js'



/**
 *答题活动分页列表
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @param {*} name 答题活动名称
 * @param {*} status 答题活动状态 0-未发布 1-进行中 2-禁用
 * @returns
 */
function getPageList(pageNow, pageSize, name, status) {
    return new Promise((resolve, reject) => {
        fetch('/subjectPractice/getPageList', {
            params: {
                pageNow,
                pageSize,
                name,
                status,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 添加
 *
 * @param {*} name 答题活动名称
 * @param {*} type 答题活动类型
 * @param {*} startTime 活动开始时间
 * @param {*} endTime 活动结束时间
 * @param {*} subjectType 使用题目分类
 * @param {*} minute 时间限制
 * @param {*} integral 积分
 * @param {*} frequency 允许答题活动次数
 * @param {*} imgUrl 展示图
 * @param {*} introduction 介绍
 * @param {*} free 是否免费
 * @param {*} fee 金额
 * @param {*} selectSubjectType 出题的题目分类范围
 * @param {*} subjectRandomRule 出题规则
 * @param {*} stauts 答题活动状态
 * @returns
 */
function saveOrUpdate(subjectPractice) {
    return new Promise((resolve, reject) => {
        postJson('/subjectPractice/saveOrUpdate', { id: subjectPractice.id, name: subjectPractice.name }, subjectPractice).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 详情
 * @param {*} id 
 * @returns
 */
function getById(id) {
    return new Promise((resolve, reject) => {
        fetch('/subjectPractice/getById', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 修改状态
 * @param {*} id 
 * @param {*} status 
 */
function updateStatus(ids, status) {
    return new Promise((resolve, reject) => {
        post('/subjectPractice/updateStatus', {
            ids,
            status
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *删除
 *
 * @param {*} id
 * @returns
 */
function removeById(id) {
    return new Promise((resolve, reject) => {
        fetch('/subjectPractice/delete', {
            params: {
                id,
            }
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}


export default {
    getPageList,
    saveOrUpdate,
    getById,
    removeById,
    updateStatus
}